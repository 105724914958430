import React from 'react';
import logoImage from "../../images/Anlook White.svg";
import NoIndex from "../NoIndex";


const MobileTopNav = () => {
  return (
    <nav className="mobile-nav">
       <NoIndex/>
      <div className="logo-container">
        {/* Since we can't use actual external images, using a placeholder */}
        <img 
          src={logoImage} 
          alt="Anlook Logo"
        />
      </div>
      <style jsx>{`
        .mobile-nav {
          display: none; /* Hidden by default */
          background-color: rgb(25, 135, 84);
          margin-bottom:5rem;
          padding: 1rem;
          justify-content: center;
          align-items: center;
        }

        .logo-container {
          width: 128px;
        }

        .logo-container img {
          width: 100%;
          height: auto;
        }

        /* Mobile styles */
        @media screen and (max-width: 768px) {
          .mobile-nav {
            display: flex; /* Show on mobile */
          }
        }
      `}</style>
    </nav>
  );
};

export default MobileTopNav;