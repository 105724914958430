import { useState, forwardRef } from "react";
import { ToastContainer } from "react-toastify";
import up from "../../images/LandingPageImage/up.svg";

import down from "../../images/LandingPageImage/down.svg";

// Using forwardRef to allow passing a ref to the FAQSection
const FAQSection = forwardRef((props, ref) => {
  const [openIndex, setOpenIndex] = useState(0);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <ToastContainer />
      <div ref={ref} className="d-flex bg-white  justify-content-center padding-5-rem  p-lg-0">
        <div className="  border-15-only width-50  p-3 " style={{ border: "1px solid #ccc" }}>
          <div className="text-center text-2-2 py-4 text-green-text">FAQs</div>

          <div   className={`faq-item border-bottom-5-css p-4 my-1 border-15-only ${openIndex === 0 ? 'faq-gray-bg' : ''}`}>
            <div className="faq-question text-3-2-dark-dark d-flex justify-content-between align-items-center">
              <div className="ps-0">What is WhatsApp Automation?</div>
              <div
                type="button"
                className="p-2"
                onClick={() => handleToggle(0)}
              >
                {openIndex === 0 ? <img src={up} alt="dropdown" height={10} />: <img src={down} alt="dropdown" height={10} />}
              </div>
            </div>
            <div className={`faq-answer ${openIndex === 0 ? "open" : ""}`}>
             <hr />
              <div className="pt-2">
                WhatsApp Automation involves using software to automate messaging and communication on WhatsApp. It helps streamline business processes such as Customer Support, Promotional Messages, and Reminders by automating repetitive tasks. Implementing WhatsApp Automation can save Time and Resources, Improve Response Times, and Enhance Customer Satisfaction.
              </div>
            </div>
          </div>

          <div className={`faq-item border-bottom-5-css p-4 my-1 border-15-only ${openIndex === 1 ? 'faq-gray-bg' : ''}`}>
            <div className="faq-question text-3-2-dark-dark d-flex justify-content-between align-items-center">
              <div className="ps-0">What is Bulk Messaging on WhatsApp?</div>
              <div
                className="p-2"
                onClick={() => handleToggle(1)}
              >
                {openIndex === 1 ?<img src={up} alt="dropdown" height={10} />: <img src={down} alt="dropdown" height={10} />}
              </div>
            </div>
            <div className={`faq-answer ${openIndex === 1 ? "open" : ""}`}>
            <hr />
              <div className="pt-2">
                WhatsApp Bulk Messaging allows you to send a large number of messages to multiple recipients simultaneously. Our platform Anlook facilitates this process by integrating with WhatsApp API, enabling you to Compose your Message, Select your Recipients, and Schedule or Send the message instantly. It's an efficient way to reach a wide audience quickly and effectively.
              </div>
            </div>
          </div>

          <div className={`faq-item border-bottom-5-css p-4 my-1 border-15-only ${openIndex === 2 ? 'faq-gray-bg' : ''}`}>
            <div className="faq-question text-3-2-dark-dark d-flex justify-content-between align-items-center">
              <div className="ps-0">Is WhatsApp Bulk Messaging legal and compliant with WhatsApp terms of service?</div>
              <div
                className="p-2"
                onClick={() => handleToggle(2)}
              >
                {openIndex === 2 ? <img src={up} alt="dropdown" height={10} />: <img src={down} alt="dropdown" height={10} />}
              </div>
            </div>
            <div className={`faq-answer ${openIndex === 2 ? "open" : ""}`}>
            <hr />
              <div className="pt-2">
                Yes, our platform ensures compliance with WhatsApp terms of service and legal regulations regarding bulk messaging. We strictly adhere to WhatsApp guidelines, ensuring that messages are sent only to opted-in recipients who have consented to receive communications from your business. Additionally, we provide features such as Message Templates and Message Scheduling to enhance compliance and improve the user experience.
              </div>
            </div>
          </div>

          <div className={`faq-item  p-4 my-1 border-15-only ${openIndex === 3 ? 'faq-gray-bg' : ''}`}>
            <div className="faq-question text-3-2-dark-dark d-flex justify-content-between align-items-center">
              <div className="ps-0">Can I Automate Messages on WhatsApp?</div>
              <div
                className="p-2"
                onClick={() => handleToggle(3)}
                style={{cursor:"pointer"}}
              >
                {openIndex === 3 ? <img src={up} alt="dropdown" height={10} />: <img src={down} alt="dropdown" height={10} />}
              </div>
            </div>
            <div className={`faq-answer ${openIndex === 3 ? "open" : ""}`}>
            <hr />
              <div className="pt-2">
                Yes, you can easily Automate WhatsApp Messages using the WhatsApp Business App or WhatsApp Business API. The WhatsApp Business App allows Automation of Welcome and Away Messages, while the API enables automation of common customer queries and notifications such as order confirmations and payment reminders.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default FAQSection;
