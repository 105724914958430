import React,{useState ,useEffect} from "react";
import { Helmet } from "react-helmet";
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
import callImage from "../../images/LandingPageImage/contactuspagehero.webp";
import Chat22 from "../../images/Chat22.svg";
import FAQSection from "./FAQ_Section";
import TickShield from "../../images/TickShield.svg";
import CrossChannel from "../../images/CrossChannel.svg";
import bgcontact from "../../images/LandingPageImage/bgContact.webp";
import signup from "../../images/signup.svg";
import address from "../../images/LandingPageImage/marker-02.svg";
import call from "../../images/LandingPageImage/phone-call-01.svg";
import email from "../../images/LandingPageImage/globe-03.svg";
const Contact = ({onSignUpClick, onLoginClick ,country,handleCountryChange  }) => {
  const [pageData, setPageData] = useState(null);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "55");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
  }, []);

  return (
    <>
          <Helmet>
        <title>Contact Anlook - Get in Touch for WhatsApp Automation Support</title>
        <meta
          name="description"
          content="Have questions about Anlook's WhatsApp automation tool? Contact our support team for assistance. We're here to help with any inquiries about our features, pricing, or technical support. Reach out to Anlook today!"
        />
        <meta
          name="keywords"
          content="Contact Anlook, Anlook support, WhatsApp automation support, Anlook technical assistance, Pricing policy anlook, Anlook help, Get in touch, customer support anlook"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Contact Us - Anlook",
            description: "Get in touch with Anlook's support team for any inquiries regarding our WhatsApp automation tool.",
            mainEntity: {
              "@type": "Organization",
              name: "Anlook",
              url: "https://www.anlook.com",
              logo: "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp", 
              contactPoint: [
                {
                  "@type": "ContactPoint",
                  telephone: "+91-9958853167",
                  contactType: "Customer Support",
                  availableLanguage: "English",
                  areaServed: "IN",
                  serviceType: "Customer Support",
                },
                {
                  "@type": "ContactPoint",
                  email: "hello@anlook.com",
                  contactType: "General Inquiries",
                  availableLanguage: "English",
                },
                {
                  "@type": "ContactPoint",
                  email: "support@anlook.com",
                  contactType: "Technical Support",
                  availableLanguage: "English",
                },
              ],
              address: {
                "@type": "PostalAddress",
                streetAddress: "Ithum Tower, Tower-B, 218, Second Floor, Sector 62",
                addressLocality: "Noida",
                postalCode: "201309",
                addressCountry: "IN",
              },
            },
          })}
        </script>
      </Helmet>
      {/* //shivani */}
<Helmet>
        <title>
          {pageData ? pageData.basic_settings.tittle : "Loading..."}
        </title>
        <meta
          name="description"
          content={pageData ? pageData.basic_settings.meta_desc : "Loading..."}
        />
        <meta
          name="keywords"
          content={
            pageData
              ? pageData.keyword_settings
                  .map((keyword) => keyword.keyword)
                  .join(", ")
              : "Loading..."
          }
        />
        <link
          rel="canonical"
          href={pageData ? pageData.basic_settings.canonical_tag : "#"}
        />
        <meta
          property="og:title"
          content={
            pageData
              ? pageData.og_settings?.og_title || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:description"
          content={
            pageData
              ? pageData.og_settings?.og_desc || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:image"
          content={pageData ? pageData.og_settings?.og_image || "#" : "#"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
        {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}

      </Helmet>

      <div className="bg-green-removed">
      <LandingNav onSignUpClick={onSignUpClick}  onLoginClick={onLoginClick}  country={country} handleCountryChange={handleCountryChange}  />

        <div
          className="row col-12 d-flex justify-content-between  padding-5-rem  py-0"
          // style={{ backgroundColor: "#EDF3FE",color: "#0c4d45"}}
          style={{
            backgroundImage: `url(${bgcontact})`, 
            backgroundPosition: "center", 
            backgroundRepeat: "no-repeat", 
            color: "#0c4d45", 
          }}
        >
          <div
            className="col-12 col-lg-6 d-flex align-items-center pe-lg-5 pe-0 pt-3 pt-lg-0"
          >
            <div>
              <div className="text-2-5  home-contact-text">Get in Touch!</div>
              <div className="pb-1 text-3-2-normal pt-4 home-contact-text">
                To contact us, simply click on the WhatsApp button located at
                the bottom right of any page on our website. Alternatively, you
                can also reach out to us via email.
              </div>              
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-end align-items-center mt-4">
            <img
              src={callImage}
              alt=""
              style={{
                width: "80%",
                height: "auto",
                maxWidth: "600px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>

        {/* Second Section */}
        <div className="d-flex justify-content-center mt-5 mb-5 padding-5-rem " >
          <div className="row col-12 d-flex justify-content-center">
         
            {/* 1 */}
          
            <div className="col-lg-4 col-12 ">
              <div className="bg-light shadow   d-flex  justify-content-center align-items-center p-2 border-15-only border-green-color-contact my-2" >
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy"
                        src={email}
                        alt="TickShield"
                        height={50}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                    Chat to support
                  </div>
                  <p className="text-center pt-2">
  {/* <img src={email} alt="" className="pe-1" height={20} /> */}
  <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@anlook.com" 
     target="_blank" 
     rel="noopener noreferrer" 
     style={{ textDecoration: 'none' }}>
    support@anlook.com
  </a>
</p>
                </div>
              </div>
            </div>
            {/* 2 */}

            <div className="col-lg-4 col-12 ">
              <div className=" bg-light shadow  d-flex justify-content-center align-items-center p-2 border-15-only border-green-color-contact my-2">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy" src={address} alt="Chat22" height={50} className="w-100" />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                  Visit us
                  </div>
                  <p className="text-center pt-2 px-2">
                  {/* <img src={address} alt="" className="pe-1" height={20} /> */}
                  {country==="IN"?
                  <>
                   Ithum Tower, Tower-B, 218, Second Floor,Sector 62 Noida-201309
                  </>
                  
                  :
                  <>
                  3807 S 7TH ST, Phoenix AZ 85040
                  </>
                  
                  }
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="col-lg-4 col-12 ">
              <div className=" bg-light shadow  d-flex justify-content-center align-items-center p-2 border-15-only border-green-color-contact my-2">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy"
                        src={call}
                        alt="CrossChannel"
                        height={50}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                    Call us
                  </div>
                  <p className="text-center pt-2">
                  {/* <img src={call} alt="" className="pe-1" height={20} /> */}
                  {country==="IN"?
                  <>
                      +91-9958853167
                  </>
                  
                  :
                  <>
                 +1 (732) 310-0621
                  </>
                  
                  }
               
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Third Section */}
        <div className='py-5 mb-5 bg-white'>
      <FAQSection  />
      </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Contact;
