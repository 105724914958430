import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "./BuyPlan.css";
import Logo from "../../images/MY-Anlook.png";
import formImage from "../../images/LandingPageImage/anlook_login_img_1.webp";
import megaphone from "../../images/LandingPageImage/megaphone.svg";
import megaphone1 from "../../images/LandingPageImage/megaphone (1).svg";
import megaphone2 from "../../images/LandingPageImage/megaphone (2).svg";
import megaphone3 from "../../images/LandingPageImage/megaphone (3).svg";
import greentick from "../../images/LandingPageImage/greentickfeature.svg";
import infoIcon from "../../images/LandingPageImage/info-circle.svg";
const GetStarted = () => {
  const location = useLocation();
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    // Get the plan data from location.state
    const { plan = [] } = location.state || {};
    setPlan(plan); // Set the plan data to state
  }, [location]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showTrialForm, setShowTrialForm] = useState(false);
  const [showBuyPlanDetails, setshowBuyPlanDetails] = useState(false);
  const [showBuyPlanForm, setShowBuyPlanForm] = useState(false);

  const openTrialForm = () => {
    setShowTrialForm(true);
    setshowBuyPlanDetails(false);
  };
  const closeTrialForm = () => {
    setShowTrialForm(false);
    setShowBuyPlanForm(false);
    setshowBuyPlanDetails(false);
  };

  // Function to handle the "Continue" button click
  const openBuyPlanDetails = () => {
    if (selectedPlan) {
      setshowBuyPlanDetails(true);
      setShowBuyPlanForm(false);
      setShowTrialForm(false);
    } else {
      console.log("Please select a plan.");
    }
  };

  const closeBuyPlanDetails = () => {
    setShowTrialForm(false);
    setShowBuyPlanForm(false);
    setshowBuyPlanDetails(false);
  };

  const openBuyPlanForm = () => {
    setShowBuyPlanForm(true);
    setshowBuyPlanDetails(false);
    setShowTrialForm(false);
  };
  const closeBuyPlanForm = () => {
    setShowBuyPlanForm(false);
    setShowTrialForm(false);
    setshowBuyPlanDetails(false);
  };

  const handlePlanSelect = (planData) => {
    // Directly store the entire plan object in the selectedPlan state
    setSelectedPlan(planData);
  };

  // ################## Free Trail Registration ##################################
  const [freetrialformData, setFreetrialformData] = useState({
    firstName: "",
    lastName: "",
    countryCode: "+91",
    mobileNo: "",
    emailId: "",
    dob: "",
    gender: "",
    password: "",
    confirmPassword: "",
    otp:""
  });
  const [freetrialformErrors, setFreetrialformErrors] = useState({});
  const [freetrialformStep, setFreetrialformStep] = useState(1);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleFreetrialformChange = (e) => {
    const { name, value } = e.target;
    setFreetrialformData({ ...freetrialformData, [name]: value });
    // Clear error for the current field on change
    setFreetrialformErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  
  
  const validateFreetrialform = () => {
    const errors = {};
    // Validation for Step 1
    if (freetrialformStep === 1) {
      if (!freetrialformData.firstName) {
        errors.firstName = "First Name is required";
        setFreetrialformErrors(errors);
        toast.error("First Name is required");
        return;
      }

      if (!freetrialformData.lastName) {
        errors.lastName = "Last Name is required";
        setFreetrialformErrors(errors);
        toast.error("Last Name is required");
        return;
      }

      if (!freetrialformData.emailId) {
        errors.emailId = "Email is required";
        setFreetrialformErrors(errors);
        toast.error("Email is required");
        return;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          freetrialformData.emailId
        )
      ) {
        errors.emailId = "Invalid email address";
        setFreetrialformErrors(errors);
        toast.error("Invalid email address");
        return;
      }

      if (!freetrialformData.countryCode) {
        errors.countryCode = "Country code is required";
        setFreetrialformErrors(errors);
        toast.error("Country code is required");
        return;
      }

      if (!freetrialformData.mobileNo) {
        errors.mobileNo = "Mobile number is required";
        setFreetrialformErrors(errors);
        toast.error("Mobile number is required");
        return;
      } else if (!/^\d{10}$/.test(freetrialformData.mobileNo)) {
        errors.mobileNo = "Invalid mobile number. Must be 10 digits.";
        setFreetrialformErrors(errors);
        toast.error("Invalid mobile number. Must be 10 digits.");
        return;
      }

      if (!freetrialformData.dob) {
        errors.dob = "Date of Birth is required";
        setFreetrialformErrors(errors);
        toast.error("Date of Birth is required");
        return;
      }

      if (!freetrialformData.gender) {
        errors.gender = "Gender is required";
        setFreetrialformErrors(errors);
        toast.error("Gender is required");
        return;
      }
    }

    // Validation for Step 2
    else if (freetrialformStep === 2) {
      if (!freetrialformData.password) {
        errors.password = "Password is required";
        setFreetrialformErrors(errors);
        toast.error("Password is required");
        return;
      } else if (freetrialformData.password.length < 6) {
        errors.password = "Password must be at least 6 characters long";
        setFreetrialformErrors(errors);
        toast.error("Password must be at least 6 characters long");
        return;
      }

      if (!freetrialformData.confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
        setFreetrialformErrors(errors);
        toast.error("Confirm Password is required");
        return;
      } else if (
        freetrialformData.password !== freetrialformData.confirmPassword
      ) {
        errors.confirmPassword = "Passwords do not match";
        setFreetrialformErrors(errors);
        toast.error("Passwords do not match");
        return;
      }
    }

    setFreetrialformErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.error("Please fix the highlighted errors.");
    }

    return Object.keys(errors).length === 0;
  };

  const handleFreetrialformContinue = async (e) => {
    e.preventDefault();

    if (validateFreetrialform()) {
      if (freetrialformStep === 1) {
        setFreetrialformStep(2);
      } else {
        // Final API call with updated request body
        const requestBody = {
          firstName: freetrialformData.firstName,
          lastName: freetrialformData.lastName,
          countryCode: freetrialformData.countryCode,
          mobileNo: freetrialformData.mobileNo,
          emailId: freetrialformData.emailId,
          dob: freetrialformData.dob,
          gender: freetrialformData.gender,
          password: freetrialformData.password,
          isTrial: "true", // Indicates this is a trial account
        };

        try {
          const response = await axios.post(
            "https://api.anlook.com/api/User/anlookRegistration",
            requestBody
          );

          if (response.data.responseCode === "00") {

            toast.success(response.data.responseMessage);
            // clearFreetrialForm();
            setFreetrialformStep(3); // Proceed to OTP step
          } else {
            toast.error(
              response.data.responseMessage || "Failed to create trial account."
            );
          }
        } catch (error) {
          toast.error("Failed to create trial account.");
        }
      }
    }
  };

  const handleFreetrialformBack = () => setFreetrialformStep(1);

  const clearFreetrialForm = () => {
    setFreetrialformData({
      firstName: "",
      lastName: "",
      countryCode: "+91",
      mobileNo: "",
      emailId: "",
      dob: "",
      gender: "",
      password: "",
      confirmPassword: "",
    });
    setFreetrialformErrors({});
    setFreetrialformStep(1);
    setShowConfirmPassword(false);
  };

  // Step 3: New OTP verification function
  const handleOTPVerificationFreeTrail = async (e) => {
    e.preventDefault(); // Prevent form submission
  
    // Check if OTP is provided
    if (!freetrialformData.otp) {
      setFreetrialformErrors((prevErrors) => ({
        ...prevErrors,
        otp: "OTP is required",
      }));
      toast.error("OTP is required");
      return;
    }
  
    try {
      const response = await axios.post(
        "https://api.anlook.com/api/User/anlookRegistrationVerify",
        {
          otp: freetrialformData.otp,
          mobile: freetrialformData.mobileNo,
        }
      );
  
      if (response.data.responseCode === "00") {
        toast.success("OTP verified successfully!");
        clearFreetrialForm();
        const token = response.data.response;
        // const callbackUrl = `${window.location.origin}/identity`;
        const callbackUrl = "https://www.anlook.com/identity";
  
        // Redirect to callback URL with access token
        window.location.href = `${callbackUrl}?accessToken=${token}`;
      } else {
        toast.error(response.data.responseMessage || "Invalid OTP");
      }
    } catch (error) {
      toast.error("OTP verification failed");
    }
  };


  // ##################### Buy Plan Registration #########################
  const [buyplanFormData, setBuyplanFormData] = useState({
    buyplanFirstName: "",
    buyplanLastName: "",
    buyplanEmail: "",
    countryCode: "+91",
    mobileNo: "",
    buyplanDob: "",
    buyplanGender: "",
    buyplanPassword: "",
    buyplanConfirmPassword: "",
  });
  const [buyplanFormErrors, setBuyplanFormErrors] = useState({});
  const [buyplanFormStep, setBuyplanFormStep] = useState(1);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleBuyplanInputChange = (e) => {
    const { name, value } = e.target;
    setBuyplanFormData({ ...buyplanFormData, [name]: value });

    setBuyplanFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const validateBuyplanForm = () => {
    const errors = {};

    // Validation for Step 1
    if (buyplanFormStep === 1) {
      if (!buyplanFormData.buyplanFirstName) {
        errors.buyplanFirstName = "First Name is required";
        setBuyplanFormErrors(errors);
        toast.error("First Name is required");
        return;
      }

      if (!buyplanFormData.buyplanLastName) {
        errors.buyplanLastName = "Last Name is required";
        setBuyplanFormErrors(errors);
        toast.error("Last Name is required");
        return;
      }

      if (!buyplanFormData.buyplanEmail) {
        errors.buyplanEmail = "Email is required";
        setBuyplanFormErrors(errors);
        toast.error("Email is required");
        return;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          buyplanFormData.buyplanEmail
        )
      ) {
        errors.buyplanEmail = "Invalid email address";
        setBuyplanFormErrors(errors);
        toast.error("Invalid email address");
        return;
      }

      if (!buyplanFormData.countryCode) {
        errors.countryCode = "Country code is required";
        setBuyplanFormErrors(errors);
        toast.error("Country code is required");
        return;
      }

      if (!buyplanFormData.mobileNo) {
        errors.mobileNo = "Mobile number is required";
        setBuyplanFormErrors(errors);
        toast.error("Mobile number is required");
        return;
      } else if (!/^\d{10}$/.test(buyplanFormData.mobileNo)) {
        errors.mobileNo = "Invalid mobile number. Must be 10 digits.";
        setBuyplanFormErrors(errors);
        toast.error("Invalid mobile number. Must be 10 digits.");
        return;
      }

      if (!buyplanFormData.buyplanDob) {
        errors.buyplanDob = "Date of Birth is required";
        setBuyplanFormErrors(errors);
        toast.error("Date of Birth is required");
        return;
      }

      if (!buyplanFormData.buyplanGender) {
        errors.buyplanGender = "Gender is required";
        setBuyplanFormErrors(errors);
        toast.error("Gender is required");
        return;
      }
    }

    // Validation for Step 2
    else if (buyplanFormStep === 2) {
      if (!buyplanFormData.buyplanPassword) {
        errors.buyplanPassword = "Password is required";
        setBuyplanFormErrors(errors);
        toast.error("Password is required");
        return;
      } else if (buyplanFormData.buyplanPassword.length < 6) {
        errors.buyplanPassword = "Password must be at least 6 characters long";
        setBuyplanFormErrors(errors);
        toast.error("Password must be at least 6 characters long");
        return;
      }

      if (!buyplanFormData.buyplanConfirmPassword) {
        errors.buyplanConfirmPassword = "Confirm Password is required";
        setBuyplanFormErrors(errors);
        toast.error("Confirm Password is required");
        return;
      } else if (
        buyplanFormData.buyplanPassword !==
        buyplanFormData.buyplanConfirmPassword
      ) {
        errors.buyplanConfirmPassword = "Passwords do not match";
        setBuyplanFormErrors(errors);
        toast.error("Passwords do not match");
        return;
      }
    }

    setBuyplanFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.error("Please fix the highlighted errors.");
    }

    return Object.keys(errors).length === 0;
  };

  const handleBuyplanFormContinue = async (e) => {
    e.preventDefault();

    if (validateBuyplanForm()) {
      if (buyplanFormStep === 1) {
        setBuyplanFormStep(2);
      } else if (buyplanFormStep === 2) {
        const requestBody = {
          firstName: buyplanFormData.buyplanFirstName,
          lastName: buyplanFormData.buyplanLastName,
          countryCode: buyplanFormData.countryCode,
          mobileNo: buyplanFormData.mobileNo,
          emailId: buyplanFormData.buyplanEmail,
          dob: buyplanFormData.buyplanDob,
          gender: buyplanFormData.buyplanGender,
          password: buyplanFormData.buyplanPassword,
          isTrial: "false",
        };

        try {
          const response = await axios.post(
            "https://api.anlook.com/api/User/anlookRegistration",
            requestBody
          );
          if (response.data.responseCode === "00") {
            toast.success(response.data.responseMessage);
            setBuyplanFormStep(3); // Proceed to OTP step
          } else {
            toast.error(
              response.data.responseMessage || "Failed to purchase plan."
            );
          }
        } catch (error) {
          toast.error("Failed to purchase plan.");
        }
      }
    }
  };

  const handleBuyplanFormBack = () => setBuyplanFormStep(1);

  const clearForm = () => {
    setBuyplanFormData({
      buyplanFirstName: "",
      buyplanLastName: "",
      buyplanEmail: "",
      countryCode: "+91",
      mobileNo: "",
      buyplanDob: "",
      buyplanGender: "",
      buyplanPassword: "",
      buyplanConfirmPassword: "",
    });
    setBuyplanFormErrors({});
    setBuyplanFormStep(1); // Reset step to 1 if needed
  };

  // Step 3: New OTP verification function
  const handleOTPVerification = async () => {
    if (!buyplanFormData.otp) {
      setBuyplanFormErrors((prevErrors) => ({
        ...prevErrors,
        otp: "OTP is required",
      }));
      toast.error("OTP is required");
      return;
    }

    // Make API call for OTP verification (replace with actual endpoint)
    try {
      const response = await axios.post(
        "https://api.anlook.com/api/User/anlookRegistrationVerify",
        {
          otp: buyplanFormData.otp,
          mobile: buyplanFormData.mobileNo,
        }
      );
      if (response.data.responseCode === "00") {
        // toast.success("OTP verified successfully!");
        clearForm();
        const token = response.data.response;
        // const callbackUrl = `${window.location.origin}/identity`;
      const callbackUrl = "https://www.anlook.com/identity";

        window.location.href = `${callbackUrl}?accessToken=${token}`;
   
   
   
      } else {
        toast.error(response.data.responseMessage || "Invalid OTP");
      }
    } catch (error) {
      toast.error("OTP verification failed");
    }
  };

  return (
    <div className="vh-100">
      <ToastContainer />
      {/* <div>
      <h1>Plan JSON Data</h1>
      <pre>{JSON.stringify(selectedPlan, null, 2)}</pre>
    </div> */}
      {/* Main Screen Buy Plan */}
      {!showTrialForm && !showBuyPlanDetails && !showBuyPlanForm && (
        <div className="buyplan-content row col-12 p-0 m-0 d-flex justify-content-center h-100">
          <div className="col-lg-6 col-12 p-0 m-0  bg-dark buyplan-left-section  d-flex align-items-center">
            <div className="p-lg-5 m-lg-5 p-3 m-3">
              {/* <h1 className="buyplan-logo">ANLOOK</h1> */}
              <div className="pb-5 ">
                <img
                  className="My-logo nav-landing-logo"
                  src={Logo}
                  alt="logo"
                />
              </div>
              <h3 className="buyplan-welcome pb-0 mb-0">WELCOME TO ANLOOK!</h3>
              <h2 className="buyplan-title">Select your Plan</h2>
              <p className="buyplan-description py-1 pt-lg-3">
                So How Does the 15-Day Free Trial Work?
                <br />
                You can use Anlook free for up to 15 Days, with full access to
                everything offered on the plan you sign up for. If you don’t
                cancel within 15 days, we’ll charge you for the plan you
                selected.
              </p>
              <button
                className="buyplan-btn buyplan-btn-trial"
                onClick={openTrialForm}
              >
                Start Free 15-Day Trial
              </button>
              <p className="buyplan-note pt-5">
                You can upgrade, downgrade, or cancel at any time with just a
                few clicks.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-12 p-0 m-0  d-flex align-items-center buyplan-right-section">
            <div className="p-lg-5 m-lg-5 p-3 m-3 w-100">
              <h3 className="buyplan-existing-title ">
                OR You Can Choose From Our Existing Plan
              </h3>
              <div>
                {Array.isArray(plan) ? (
                  plan.map((planItem) => (
                    <div
                      key={planItem.id}
                      className="buyplan-plan-option"
                      onClick={() => handlePlanSelect(planItem)}
                    >
                      <div className="row col-12 p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="col-1 m-0 p-0 d-flex align-items-center justify-content-center">
                          <input
                            type="radio"
                            name="plan"
                            id={planItem.id}
                            checked={selectedPlan?.id === planItem.id}
                            onChange={() => handlePlanSelect(planItem)}
                            className="me-1"
                          />
                        </div>
                        <div className="col-11 p-0 m-0">
                          <div className="d-flex justify-content-between">
                            <h4 className="buyplan-plan-name">
                              {planItem.plan_name}
                            </h4>
                            <span className="buyplan-price text-dark">
                              ₹ {parseFloat(planItem.plan_price).toFixed(2)}/mo.
                            </span>
                          </div>
                          <p className="buyplan-plan-description">
                            {planItem.plan_desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    key={plan.id}
                    className="buyplan-plan-option"
                    onClick={() => handlePlanSelect(plan)}
                  >
                    <div className="row col-12 p-0 m-0 d-flex align-items-center justify-content-center">
                      <div className="col-1 m-0 p-0 d-flex align-items-center justify-content-center">
                        <input
                          type="radio"
                          name="plan"
                          id={plan.id}
                          checked={selectedPlan?.id === plan.id}
                          onChange={() => handlePlanSelect(plan)}
                        />
                      </div>
                      <div className="col-11 p-0 m-0">
                        <div className="d-flex justify-content-between">
                          <h4 className="buyplan-plan-name">
                            {plan.plan_name}
                          </h4>
                          <span className="buyplan-price">
                            ₹ {parseFloat(plan.plan_price).toFixed(2)}/mo.
                          </span>
                        </div>
                        <p className="buyplan-plan-description">
                          {plan.plan_desc}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <button
                className="buyplan-btn buyplan-btn-continue"
                onClick={openBuyPlanDetails}
                disabled={!selectedPlan} // Disable the button if no plan is selected
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Free Traial Screen */}
      {showTrialForm && !showBuyPlanDetails && (
        <div className="buyplan-content row col-12 d-flex justify-content-center h-100">
          {/* Left section Form */}
          <div className="col-lg-6 col-12 p-0 m-0  bg-dark buyplan-left-section  d-flex align-items-center">
            <div className="p-lg-5 m-lg-5 p-3 m-3">
              <h2 className="buyplan-trial-title pb-3">
                Get started with a Trial Account on{" "}
                <span className="buyplan-logo">Anlook</span>
              </h2>
              <form className="buyplan-trial-form">
                {freetrialformStep === 1 && (
                  <div className="row col-12 m-0 p-0">
                    <div className="col-lg-6 p-0 m-0 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="firstName">
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder="First Name"
                          value={freetrialformData.firstName}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.firstName ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 m-0 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="lastName">
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Last Name"
                          value={freetrialformData.lastName}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.lastName ? "is-invalid" : ""
                          }`}
                        />
                      </div>

                          

                    </div>
                    <div className="col-lg-12 p-0 m-0 col-12 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="emailId">
                          Business Email
                        </label>
                        <input
                          type="email"
                          name="emailId"
                          id="emailId"
                          placeholder="Business Email"
                          value={freetrialformData.emailId}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.emailId ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 p-0 m-0 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="countryCode">
                          Country
                        </label>
                        <select
                          name="countryCode"
                          id="countryCode"
                          value={freetrialformData.countryCode}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.countryCode ? "is-invalid" : ""
                          }`}
                        >
                          <option value="+91">+91</option>
                          <option value="+1">+1</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-10 p-0 m-0 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="mobileNo">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          name="mobileNo"
                          id="mobileNo"
                          placeholder="Phone Number"
                          value={freetrialformData.mobileNo}
                          onChange={handleFreetrialformChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, ""); // Allow only digits
                          }}
                            maxLength="10"
                          className={`form-control buyplan-input ${
                            freetrialformErrors.mobileNo ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 m-0 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="dob">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          name="dob"
                          id="dob"
                          value={freetrialformData.dob}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.dob ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>


                    <div className="col-lg-6 p-0 m-0 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="gender">
                          Gender
                        </label>
                        <select
                          name="gender"
                          id="gender"
                          value={freetrialformData.gender}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.gender ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {freetrialformStep === 2 && (
                  <div className="row col-12 p-0 m-0">
                    <div className="col-12 p-0 m-0 ">
                      <h4 className="p-2">Set up your password</h4>
                    </div>
                    <div className="col-lg-12 col-12 p-0 m-0 ">
                      <div className="p-2">
                        <label className="buyplan-label" tmlFor="password">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={freetrialformData.password}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.password ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-12 p-0 m-0 ">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          tmlFor="confirmPassword"
                        >
                          Confirm Password
                        </label>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          value={freetrialformData.confirmPassword}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.confirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-12 p-0 m-0 ">
                      <div className="p-2">
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="showPasswordCheckbox"
                            checked={showConfirmPassword}
                            onChange={toggleShowConfirmPassword}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showPasswordCheckbox"
                          >
                            Show Password
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                  {freetrialformStep === 3 && (
                  <div className="row col-12 d-flex justify-content-center align-items-between">
                    <div className="col-12 p-0 m-0 ">
                      <h4 className="p-2">OTP Verification</h4>
                    </div>
                    <div className="col-lg-12 col-12 p-0 m-0">
                      <div className="p-2">
                        {/* <label className="buyplan-label" htmlFor="otp">
                          Enter OTP
                        </label> */}
                        <div className="text-secondary">OTP has been Sent to {freetrialformData.mobileNo}</div>
                        <input
                          type="text"
                          id="otp"
                          name="otp"
                          placeholder="Please Enter OTP"
                          value={freetrialformData.otp}
                          onChange={handleFreetrialformChange}
                          className={`form-control buyplan-input ${
                            freetrialformErrors.otp ? "is-invalid" : ""
                          }`}
                        />
                  
                      </div>
                      <div className="p-2">
                             
                      <button
                        onClick={handleOTPVerificationFreeTrail}
                        className="btn green-bg text-white"
                      >
                        Verify OTP 
                      </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className=" p-0 m-0">
                  <div className="d-flex justify-content-start  p-2 gap-2">
                    {freetrialformStep === 2 && (
                      <button
                        onClick={handleFreetrialformBack}
                        className="btn btn-secondary"
                      >
                        Back
                      </button>
                    )}
                    {freetrialformStep !== 3 && (
                      <button  onClick={handleFreetrialformContinue}  className="btn green-bg text-white">
                        {freetrialformStep === 1 ? "Continue" : "Send OTP"}
                      </button>
                    )}
                   
                  


                  </div>
                </div>





              </form>
            </div>
          </div>
          {/* Right section Image */}

          <div className="col-lg-6 col-12 p-0 m-0 d-flex align-items-center buyplanTrailSection-rightSection">
            <div className="p-4  p-lg-5">
              <div className="pb-3">
                <img
                  className="My-logo nav-landing-logo"
                  src={Logo}
                  alt="logo"
                />
              </div>
              <div>
                <h1 className="buyplanTrailSection-heroTitle font-size-1 text-start pt-2">
                  Unlock the Full Potential of Your Business with{" "}
                  <span className="text-success">WhatsApp</span>
                </h1>
              </div>

              <div className="row col-12 p-0 m-0">
                <div className="col-12 col-lg-6 p-0 m-0">
                  <div className="row col-12 p-0 m-0 mt-lg-4 mt-3">
                    <div className="col-12 col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <div className="buyplanTrailSection-text d-flex align-items-center">
                          <img
                            src={megaphone}
                            alt="feature icon"
                            style={{ height: "2rem" }}
                            className="buyplanTrailSection-featureIcon me-2"
                          />{" "}
                          <span className="text-green-buyplan">
                            Targeted Campaigns
                          </span>
                        </div>
                        <div className="mt-2">
                          Deliver personalized offers that drive results
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <div className="buyplanTrailSection-text d-flex align-items-center">
                          <img
                            src={megaphone1}
                            style={{ height: "2rem" }}
                            alt="feature icon"
                            className="buyplanTrailSection-featureIcon me-2"
                          />
                          <span className="text-green-buyplan">
                            {" "}
                            Ready-to-Use Templates
                          </span>
                        </div>
                        <div className="mt-2">
                          Send timely updates and reminders with ease
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <div className="buyplanTrailSection-text d-flex align-items-center">
                          <img
                            src={megaphone2}
                            style={{ height: "2rem" }}
                            alt="feature icon"
                            className="buyplanTrailSection-featureIcon me-2"
                          />
                          <span className="text-green-buyplan">
                            {" "}
                            24/7 Instant Engagement
                          </span>
                        </div>
                        <div className="mt-2">
                          Keep your customers connected with no-code chatbots
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <div className="buyplanTrailSection-text d-flex align-items-center">
                          <img
                            src={megaphone3}
                            style={{ height: "2rem" }}
                            alt="feature icon"
                            className="buyplanTrailSection-featureIcon me-2"
                          />
                          <span className="text-green-buyplan">
                            {" "}
                            Product Catalogue
                          </span>
                        </div>
                        <div className="mt-2">
                          Quickly send catalogues to multiple customers,
                          boosting your sales.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 p-0 m-0 d-flex align-items-center">
                  <div className="buyplanTrailSection-heroImage">
                    <img
                      className="img-fluid buyplanTrailSection-responsiveImg"
                      src={formImage}
                      alt=""
                    />
                  </div>
                </div>

                <div className="text-gray">
                  Grow smarter, engage faster, and streamline your processes
                  with Anlook's WhatsApp automation solutions!
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Buy Plan Details Screen */}
      {showBuyPlanDetails && !showTrialForm && selectedPlan && (
        <div className="vertical-height-100 gredient-bg d-flex justify-content-center align-items-center">
          <div className="buyplandetails-container ">
            <div className="buyplandetails-header">
              <div>Plan Details</div>
            </div>
            <div className="buyplandetails-content">
              <p className="text-2-rem font-weight-600  text-dark">
                {selectedPlan.plan_name}
              </p>
              <p className="text-1-rem ">{selectedPlan.plan_desc}</p>

              <div className="buyplandetails-pricing">
                <div className="buyplandetails-fee">
                  <span className="text-1-rem">
                    {/* <img src={infoIcon} alt="icon" className="me-2" /> */}
                    Setup Fee <small>(One-Time Fee)</small>
                    <img src={infoIcon} alt="icon" className="ms-2" />
                  </span>
                  <span className="text-1-rem ">
                    ₹ {parseFloat(selectedPlan.setup_fee).toFixed(2)}
                  </span>
                </div>
                <div className="buyplandetails-fee">
                  <span className="text-1-rem ">Plan Fee</span>
                  <span className="text-1-rem ">
                    ₹ {parseFloat(selectedPlan.plan_price).toFixed(2)}/mo.
                  </span>
                </div>
                <div className="buyplandetails-total-fee pt-3">
                  <span className="text-1-rem ">Total Fee</span>
                  <span className="text-1-rem ">
                    ₹ {parseFloat(selectedPlan.total_price).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="buyplandetails-features">
                <h3>Features</h3>
                <ul>
                  {selectedPlan.features
                    .filter((feature) => feature.status === "Y")
                    .map((feature, index) => (
                      <li key={index}>
                        <img src={greentick} alt="check" />{" "}
                        <span className="text-1-rem ">
                          {" "}
                          {feature.feature_name}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="buyplandetails-actions">
                <button
                  className="buyplandetails-change-button"
                  onClick={closeBuyPlanDetails}
                >
                  Change Plan
                </button>
                <button
                  className="buyplandetails-proceed-button"
                  onClick={openBuyPlanForm}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Buy Plan Registration From Screen */}
      {showBuyPlanForm && !showTrialForm && !showBuyPlanDetails && (
        <div className="vertical-height-100 gredient-bg d-flex justify-content-center align-items-center">
          <div className="buyplandetails-container ">
            <form onSubmit={handleBuyplanFormContinue}>
              <div className="buyplandetails-header">
                <div>Fill Your Details to Continue</div>
              </div>
              <div className="buyplanRegistration-content">
                {buyplanFormStep === 1 && (
                  <div className="row col-12 d-flex justify-content-center align-items-between">
                    <div className="col-12 col-lg-6 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanFirstName"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="buyplanFirstName"
                          name="buyplanFirstName"
                          placeholder="First Name"
                          value={buyplanFormData.buyplanFirstName}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanFirstName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanLastName"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="buyplanLastName"
                          name="buyplanLastName"
                          placeholder="Last Name"
                          value={buyplanFormData.buyplanLastName}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanLastName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="buyplanEmail">
                          Email
                        </label>
                        <input
                          type="email"
                          id="buyplanEmail"
                          name="buyplanEmail"
                          placeholder="Email"
                          value={buyplanFormData.buyplanEmail}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanEmail ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-lg-2 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="countryCode">
                          Country
                        </label>
                        <select
                          id="countryCode"
                          name="countryCode"
                          value={buyplanFormData.countryCode}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.countryCode ? "is-invalid" : ""
                          }`}
                        >
                          <option value="+91">+91</option>
                          <option value="+1">+1</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-lg-10 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="mobileNo">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="mobileNo"
                          name="mobileNo"
                          placeholder="Mobile Number"
                          value={buyplanFormData.mobileNo}
                          onChange={handleBuyplanInputChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, ""); // Allow only digits
                          }}
                            maxLength="10"
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.mobileNo ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="buyplanDob">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          id="buyplanDob"
                          name="buyplanDob"
                          value={buyplanFormData.buyplanDob}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanDob ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanGender"
                        >
                          Gender
                        </label>
                        <select
                          id="buyplanGender"
                          name="buyplanGender"
                          value={buyplanFormData.buyplanGender}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanGender ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {buyplanFormStep === 2 && (
                  <div className="row col-12 d-flex justify-content-center align-items-between">
                    <h4 className="p-2">Set up your password</h4>
                    <div className="col-lg-12 col-12 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanPassword"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          id="buyplanPassword"
                          name="buyplanPassword"
                          placeholder="Password"
                          value={buyplanFormData.buyplanPassword}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanPassword
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-12 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanConfirmPassword"
                        >
                          Confirm Password
                        </label>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="buyplanConfirmPassword"
                          name="buyplanConfirmPassword"
                          placeholder="Confirm Password"
                          value={buyplanFormData.buyplanConfirmPassword}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanConfirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="showPasswordCheckbox"
                            checked={showConfirmPassword}
                            onChange={toggleShowConfirmPassword}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showPasswordCheckbox"
                          >
                            Show Password
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {buyplanFormStep === 3 && (
                  <div className="row col-12 d-flex justify-content-center align-items-between">
                    <h4 className="p-2">OTP Verification</h4>
                    <div className="col-lg-12 col-12 p-0 m-0">
                      <div className="p-2">
                        {/* <label className="buyplan-label" htmlFor="otp">
                          Enter OTP
                        </label> */}
                          <div className="text-secondary">OTP has been Sent to {buyplanFormData.mobileNo}</div>
                        <input
                          type="text"
                          id="otp"
                          name="otp"
                          placeholder="Please Enter OTP"
                          value={buyplanFormData.otp}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.otp ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row col-12 p-0 m-0 ">
                  <div className="d-flex justify-content-start p-0 m-0 mt-3 gap-3">
                  {buyplanFormStep === 2 && (
                      <button
                        onClick={handleBuyplanFormBack}
                        className="btn btn-secondary"
                      >
                        Back
                      </button>
                    )}
                    {buyplanFormStep !== 3 && (
                      <button type="submit" className="btn green-bg text-white">
                        {buyplanFormStep === 1 ? "Continue" : "Send OTP"}
                      </button>
                    )}
                   
                    {buyplanFormStep === 3 && (
                      <button
                        onClick={handleOTPVerification}
                        className="btn green-bg text-white"
                      >
                        Verify OTP
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default GetStarted;
