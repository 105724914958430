import { useState,useEffect } from "react";
import axios from "axios";
import {  ToastContainer } from "react-toastify";
import author from "../../../images/LandingPageImage/Author_img.svg";
import { Link } from "react-router-dom";
import ss1 from "../../../images/LandingPageImage/web_campaign_broadcast_1x.webp";
import ss2 from "../../../images/LandingPageImage/web_chat_bot_1x.webp";
import ss3 from "../../../images/LandingPageImage/web_inbox_team_1x.webp";
import ArrowLeft from "../../../images/ArrowLeft.svg"
import ArrowRight from "../../../images/ArrowRight.svg"
import CampaignManagment from "../../../images/Campaign Management.png";
import userManagement from "../../../images/User Management.png";
import Dashboard from "../../../images/Dashboard Overview.png";
import chatbot from "../../../images/Chat Bots.png";
import chat from "../../../images/Chat & Contact Management.png";
import orderMangement from "../../../images/Order & Catalogue Management.png";
import LeadManagement from "../../../images/Lead Management.png";
import templateManagment from "../../../images/Template Management.png";
const cardData = [
  {
    id: 1,
    image: chat, // Replace with actual image name
    title: "Chat & Contact Management",
    description:
      "Manage your WhatsApp conversations and contacts efficiently in one place, ensuring you never miss an important lead or customer query.",
  },
  {
    id: 2,
    image: templateManagment, // Replace with actual image name
    title: "Template Management",
    description:
      "Simplify your messaging with reusable templates that you can easily create, manage, and deploy for consistent communication.",
  },
  {
    id: 3,
    image: CampaignManagment, // Replace with actual image name
    title: "Campaign Management",
    description:
      "Launch, monitor, and adjust your marketing campaigns on WhatsApp with ease, all from a single dashboard.",
  },
  {
    id: 4,
    image: chatbot, // Replace with actual image name
    title: "Chat Bots",
    description:
      "Set up chatbots to handle routine inquiries and engage with customers 24/7, ensuring prompt responses and increased satisfaction.",
  },
  {
    id: 5,
    image: LeadManagement, // Replace with actual image name
    title: "Lead Management",
    description:
      "Keep track of potential customers and convert leads more effectively with an organized and actionable lead management system.",
  },
  {
    id: 6,
    image: orderMangement, // Replace with actual image name
    title: "Order & Catalogue Management",
    description:
      "Manage your product catalog and orders seamlessly, allowing for easy updates and a streamlined order process.",
  },
  {
    id: 7,
    image: userManagement, // Replace with actual image name
    title: "User Management",
    description:
      "Manage user permissions and roles efficiently to ensure secure and appropriate access to your app’s features.",
  },
  {
    id: 8,
    image: Dashboard, // Replace with actual image name
    title: "Dashboard Overview",
    description:
      "View key metrics and performance indicators in a centralized dashboard, giving you quick insights to drive decision-making.",
  },
];
const BlogSection=()=>{

    const [index, setIndex] = useState(0);

    const handlePrevious = () => {
      setIndex((prevIndex) =>
        prevIndex === 0 ? blogData.length - (isMobile ? 1 : 4) : prevIndex - 1
      );
    };
  
    const handleNext = () => {
      setIndex((prevIndex) =>
        prevIndex >= blogData.length - (isMobile ? 1 : 4) ? 0 : prevIndex + 1
      );
    };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [blogData, setBlogData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post("https://api.goseo.in/api/Seo/GetProductBlogs", {
        product_name: "anlook",
      })
      .then((response) => {
        if (response.data.status === "0") {
          const apiData = response.data.data.map((post) => ({
            id: post.post_id,
            image: post.post_featured_image,
            title: post.post_title,
            description: post.post_excerpt,
            author: post.post_author,
            post_slug: post.post_slug,
            date: post.created_at,
          }));
          setBlogData(apiData); // Set the fetched data into state
        }
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      })
      .finally(() => {
        setLoading(false); // Turn off loading once data is fetched
      });
  }, []);
      // Shimmer Component to show while loading
  const ShimmerBlogCard = () => {
    return (
      <div className=" card-blog">
        <div className="shimmer-image-blog"></div>
        <div className="shimmer-content ">
          <div className="shimmer-line shimmer-author"></div>
          <div className="shimmer-line shimmer-date"></div>
          <div className="shimmer-line shimmer-title"></div>
          <div className="shimmer-line shimmer-description"></div>
        </div>
      </div>
    );
  };
return (

    <>
        <ToastContainer />

        <div className="d-flex justify-content-center landing-body-bg  padding-5-rem bg-soil-landing py-3 py-lg-5 mt-4">
        <div>
          <div className="d-flex justify-content-center text-center text-2-2 pb-2">
            Blog updates
          </div>
          <div className="d-flex justify-content-center text-center pb-4 px-2 px-lg-0 ">
            Stay informed with our latest blog posts and join the wave of
            innovation.
          </div>

          <div className="container">
          <div className="home-blog-card-container">
  {blogData.slice(index, index + (isMobile ? 1 : 4)).reverse().map((card) => (
    <div key={card.id} className="home-blog-card">
      <div className="home-blog-image">
      <img loading="lazy" src={card.image} alt={card.title} className="" />

      </div >
      <div className="px-3 py-3 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
                        <img src={author}  alt="author" height={20} className="me-1" /> <p className="p-0 m-0 text-3-2-gray ">{card.author}</p>
                        </div>
                        {/* <p className="p-0 m-0 text-3-2-gray">{new Date(card.date).toLocaleDateString('en-GB')}</p> */}
                      </div> 

      <p className=" px-3 pt-0 mt-0 text-3-2-dark-notbold">
        <Link to={`/blog/${card.post_slug}`} className="text-3-2-dark-notbold home-blog-card-title">
          {card.title}
        </Link>
      </p>
      <p className="pb-2 px-3">{card.description.slice(0, 50)}....</p>
      <p className="px-3 pb-2">
        <Link to={`/blog/${card.post_slug}`} className="home-blog-read-more">
          Read more →
        </Link>
      </p>
    </div>
  ))}
</div>

            <div className=" d-flex justify-content-center pt-4">
              <div
                onClick={handlePrevious}
                disabled={index === 0}
                className="cursor-pointer me-5"
              >
                <img loading="lazy" src={ArrowLeft} alt="" />
              </div>
              <div
                onClick={handleNext}
                disabled={index >= cardData.length - 3}
                className="cursor-pointer "

              >
            <img loading="lazy" src={ArrowRight} alt="" />
              </div>
            </div>

            {/* <div className="buttons-blog d-flex justify-content-center">
              <div
                onClick={handlePrevious}
                disabled={index === 0}
                className="button-blog p-1 pe-2 mx-2 text-dark"
              >
                ◄
              </div>
              <div
                onClick={handleNext}
                disabled={index >= cardData.length - 3}
                className=" p-1 ps-2 button-blog text-dark"
              >
                ►
              </div>
            </div> */}
          </div>
        </div>
      </div>
    
    </>
)
}

export default BlogSection;