// src/features/apiSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  baseUrl: 'https://api.anlook.com', // Example base URL
  config: {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
    },
  },
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setBaseUrl: (state, action) => {
      state.baseUrl = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
  },
});

export const { setBaseUrl, setConfig } = apiSlice.actions;
export const selectBaseUrl = (state) => state.api.baseUrl;
export const selectConfig = (state) => state.api.config; // Selector for the config
export default apiSlice.reducer;
