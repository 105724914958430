import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "./FacebookOnBoarding.css";
import onboard from "../../images/onboardimage.png";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import image4 from "../../images/image4.png";
import image5 from "../../images/image5.png";

const FacebookOnBoarding = ({ userID }) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [sdkResponse, setSdkResponse] = useState(null);
  const [waResponse, setWaResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFBReady, setIsFBReady] = useState(false);
  const user = userID;

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "801281555533147",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v21.0",
      });
      setIsFBReady(true); // Indicate that FB is ready to use
    };

    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const connectionSteps = [
    {
      number: 1,
      title: "Login to Facebook",
      description:
        "Ensure it's the admin account for your meta Business Manager.",
      image: image1,
    },
    {
      number: 2,
      title: "Fill your Business information",
      description: "Fill your Business information.",
      image: image2,
    },
    {
      number: 3,
      title: "Create New WhatsApp",
      description: "Create New WhatsApp business account & profile.",
      image: image3,
    },
    {
      number: 4,
      title: "Fill WhatsApp Business Profile info",
      description: "Fill WhatsApp Business profile.",
      image: image4,
    },
    {
      number: 5,
      title: "Verify your Business number on Meta",
      description:
        "Final step to connect your WhatsApp Business account. Review and confirm all settings.",
      image: image5,
    },
  ];

  const handleConnectNow = () => {
    setIsCarouselOpen(true);
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) =>
      Math.min(prevStep + 1, connectionSteps.length - 1)
    );
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const pushResponseCall = async (responseData) => {
    if (!responseData) return;

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const response = await axios.post(
        "https://apiuat.anlook.com/api/Account/v1/facebookSignupOnboardingWebhook",
        responseData,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          // setWaResponse({ ...data, userid: user });
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            pushResponseCall({ ...data, userid: userID });
            console.log(
              "Phone number ID ",
              phone_number_id,
              " WhatsApp business account ID ",
              waba_id
            );
          } else if (data.event === "CANCEL") {
            const { current_step } = data.data;
            // setWaResponse({ ...data, userid: user });
            console.warn("Cancel at ", current_step);
          } else if (data.event === "ERROR") {
            const { error_message } = data.data;
            console.error("error ", error_message);
          }
        }

        document.getElementById("session-info-response").textContent =
          JSON.stringify(data, null, 2);
      } catch {
        console.log("Non JSON Responses", event.data);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // useEffect(() => {
  //   // SDK code facebook
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: "801281555533147",
  //       autoLogAppEvents: true,
  //       xfbml: true,
  //       version: "v21.0",
  //     });
  //     setIsFBReady(true);
  //   };

  //   const script = document.createElement("script");
  //   script.src = "https://connect.facebook.net/en_US/sdk.js";
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);
  //   // setWaResponse({ ...data, userid: userID });

  //   // Add event listener for embedded signup responses
  //   const handleMessage = (event) => {
  //     if (
  //       event.origin !== "https://www.facebook.com" &&
  //       event.origin !== "https://web.facebook.com"
  //     ) {
  //       return;
  //     }

  //     try {
  //       const data = JSON.parse(event.data);
  //       if (data.type === "WA_EMBEDDED_SIGNUP") {
  //         setWaResponse({ ...data, userid: userID });
  //         if (data.event === "FINISH") {
  //           const { phone_number_id, waba_id } = data.data;
  //           setWaResponse({ ...data, userid: userID });
  //           console.log(
  //             "Phone number ID ",
  //             phone_number_id,
  //             " WhatsApp business account ID ",
  //             waba_id
  //           );
  //         } else if (data.event === "CANCEL") {
  //           const { current_step } = data.data;
  //           console.warn("Cancel at ", current_step);
  //         } else if (data.event === "ERROR") {
  //           const { error_message } = data.data;
  //           console.error("error ", error_message);
  //         }
  //       }

  //       document.getElementById("session-info-response").textContent =
  //         JSON.stringify(data, null, 2);
  //     } catch {
  //       console.log("Non JSON Responses", event.data);
  //     }
  //   };

  //   window.addEventListener("message", handleMessage);

  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //     // document.body.removeChild(script);
  //   };
  // }, []);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      pushResponseCall({ ...response, userid: userID });
      // setSdkResponse(response);
      toast.success("Successfully connected to Facebook!");
    } else {
      toast.error("Facebook login failed. Please try again.");
    }
  };

  const launchWhatsAppSignup = () => {
    if (isFBReady && window.FB) {
      window.FB.login(fbLoginCallback, {
        // config_id: "565106129591059", esConfig
        // config_id: "1035022388668066", //esConfig 2
        // 1164797434986506
        config_id: "1164797434986506", //esConfig 3

        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "2",
        },
      });
    } else {
      console.error("Facebook SDK is not loaded yet.");
    }
  };

  return (
    <div className="facebook-onboarding-container">
      <ToastContainer />
      {!isCarouselOpen ? (
        <div className="onboarding-initial-screen d-flex">
          <img src={onboard ? onboard : "cool image"} alt="Onboarding" />
          <div className="onboarding-test-first">
            <div className="onboarding-header">
              <div className="d-flex">
                <h2>To Experience Messaging, Please connect you </h2>{" "}
                <h2 className="onboarding-header" style={{ color: "green" }}>
                  {" "}
                  WhatsApp{" "}
                </h2>{" "}
              </div>
              <h2>number.</h2>

              <p>
                You can connect your WhatsApp Number to start messaging contacts
                or receive messages from them.
              </p>
            </div>
            <div className="onboarding-prerequisites">
              <h3>Few things to know before you connect</h3>
              <ul>
                <li>
                  → You must have Admin access to your Meta Business Manager
                </li>
                <li>
                  → You must have a phone number that does not have a WhatsApp
                  Account
                </li>
                <li>
                  • Deregister number that has been used with WhatsApp Business
                  before Learn more
                </li>
              </ul>
            </div>
            <div className="onboarding-actions">
              <button onClick={handleConnectNow} className="connect-now-btn">
                {loading ? "Connecting..." : "Connect Now"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="onboarding-carousel-screen mt-4">
            <div className="mx-4 mt-4">
              <h3>You are almost there..!</h3>
              <p>
                Continue with Facebook and follow the instructions below to link
                your number
              </p>
              <div className="carousel-content">
                <div className="carousel-header">
                  <div className="carousel-step-indicator">
                    <span className="step-number">
                      {connectionSteps[currentStep].number}
                    </span>
                    <h3>{connectionSteps[currentStep].title}</h3>
                  </div>
                </div>
                <p className="carousel-step-description">
                  {connectionSteps[currentStep].description}
                </p>

                <div className="carousel-image-container">
                  <button
                    onClick={handlePrevStep}
                    disabled={currentStep === 0}
                    className="nav-btn prev-btn"
                  >
                    &larr;
                  </button>
                  <div className="carousel-image">
                    <img
                      src={connectionSteps[currentStep].image}
                      alt={`Step ${connectionSteps[currentStep].number}`}
                    />
                  </div>
                  <button
                    onClick={handleNextStep}
                    disabled={currentStep === connectionSteps.length - 1}
                    className="nav-btn next-btn"
                  >
                    &rarr;
                  </button>
                </div>
              </div>
            </div>

            <div className="carousel-footer">
              <button
                className="connect-facebook-btn"
                onClick={launchWhatsAppSignup}
              >
                Connect to Facebook
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FacebookOnBoarding;
