import React, { useEffect } from 'react';

const TrackingPage = () => {
  
  // Custom UUID generation function
  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  
  useEffect(() => {
    const sessionId = generateUUID();
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has('sessionid')) {
      urlParams.set('sessionid', sessionId);

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;

      window.history.replaceState(null, '', newUrl);
    }
  }, []);

  return (
    <div>
      {/* <h1>Tracking Page</h1>
      <p>The URL has been updated with a session ID!</p> */}
    </div>
  );
};

export default TrackingPage;
