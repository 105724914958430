import React, { useState, useEffect, useDebugValue, useRef } from "react";
import Swal from "sweetalert2";
import trash from "../../images/trash.svg";
import { Modal } from "react-bootstrap";
import cut from "../../images/close-circle.svg";
import NoIndex from "..//NoIndex";
import ContectBook from "./Email_add";

import axios from "axios";
import {
  Form,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Chat_BG from "../../images/chat_BG.png";
import wpImage from "../../images/Whatsapp.svg";

import sendImage from "../../images/Send.svg";

const EmailChats = ({ userID }) => {
  const messagesEndRef = useRef(null);
  const [selectedContactId, setSelectedContactId] = useState("");
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatShow, setChatShow] = useState(true);
  const [allContectShow, setAllContectShow] = useState(false);
  const [activeButton, setActiveButton] = useState("1");
  const [mobileNumberSelected, setMobileNumberSelected] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    scrollToBottom();
  }, [chats]); // Dependency on chats ensures that the effect runs whenever chats change

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    }, 0); // Using a slight delay to ensure messages are fully rendered before scrolling
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
    );
    const raw = JSON.stringify({
      userid: userID,
      secret: "NA",
      message: message,
      contact: mobileNumberSelected,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/sendInstantMessage",
        requestOptions
      );
      const result = await response.text();
      console.log(result);
      setMessage("");
      fetchChats();
      // Optionally you can show a success message to the user
    } catch (error) {
      console.error(error);
      // Optionally you can show an error message to the user
    }
    setLoading(false);
  };
  const fetchChats = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
    );
    const raw = JSON.stringify({
      userid: userID,
      secret: "string",
      contact: mobileNumberSelected,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/getWhatsappConversation",
        requestOptions
      );
      const result = await response.json();
      if (result.responseCode === "00") {
        if (result.data !== null) {
          setChats(result.data);
        }
      } else {
        console.error(
          "Error fetching previous messages:",
          result.responseMessage
        );
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchChats();
  }, [selectedContactId, mobileNumberSelected]);

  // Handler to select a contact
  const handleSelectContact = (id) => {
    setSelectedContactId(id);
  };

  const handleSendMessage = () => {
    if (!messageText.trim()) return;
    const newMessage = {
      fromMe: true,
      text: messageText,
      timestamp: new Date().toLocaleTimeString(),
    };
    const updatedMessages = {
      ...messages,
      [selectedContactId]: [...(messages[selectedContactId] || []), newMessage],
    };

    setMessages(updatedMessages);
    setMessageText("");
  };

  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
      );

      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://api.anlook.com/api/Campaign/getContacts",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setContacts(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Call fetchData only once when the component mounts
    fetchData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    tags: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "tags") {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        tags: value.split(",").map((tag) => tag.trim()),
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };

  const handleEditContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      const requestBody = {
        userid: userID,
        contactNumber: contactInfo.contactNumber,
        contactEmail: contactInfo.contactEmail,
        contactName: contactInfo.contactName,
        gender: contactInfo.gender,
        tags: contactInfo.tags,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Campaign/editContact",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });
        setShowModal(false);
        fetchData();
      } else {
        toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };

  const deleteContactAPI = async (number) => {
    try {
      const requestBody = {
        userid: userID,
        contactNumber: number,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      // Show confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        const response = await axios.post(
          "https://api.anlook.com/api/Campaign/deleteContact",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          // Contact deleted successfully
          toast.success(response.data.responseMessage);
          fetchData();
        } else {
          // Failed to delete contact
          toast.error(response.data.responseMessage);
        }
      }
    } catch (error) {
      console.error(error);
      // Handle error here
      Swal.fire("Error!", "An error occurred while deleting contact.", "error");
    }
  };

  const setSelectedContact = (selectedContactId) => {
    console.log("hii");
    console.log(selectedContactId);

    setShowModal(true);
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        tags: selectedContact.tags,
      });

      console.log(contactInfo);
    }
  };
  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>

      <div style={{ display: "flex", height: "100vh" }}>
        <div className="contactsList  ">
          <div className="  p-4 ">
            <div className=" row  d-flex justify-content-between border-bottom-chat ">
              
              <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "2" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setAllContectShow(true);
                    setChatShow(false);
                    setActiveButton("2");
                    setMobileNumberSelected("");
                    setSelectedContactId("");
                  }}
                >
                  Emails
                </button>
              </div>
              {/* <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "3" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setSelectedContactId(null);
                    setActiveButton("3");
                  }}
                >
                  + Add
                </button>
              </div> */}
            </div>
          </div>

        

          {allContectShow && (
            <>
              <div className=" px-4 text3 font-bold-400">All Emails</div>
              {contacts.map((contact) => (
                <div
                  key={contact.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                    backgroundColor:
                      selectedContactId === contact.id
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                  className="ms-2"
                >
                  {/* Contact Name */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="d-flex justify-content-start"
                      onClick={() => {
                        setMobileNumberSelected(contact.contact_number);
                        setSelectedContactId(contact.id);
                        setChats([]);
                      }}
                    >
                      <img
                        src={wpImage}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                        className="  p-1"
                      />
                      <div>
                        <div>{contact.contact_name}</div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#666",
                            marginTop: "5px",
                          }}
                        >
                          {contact.contact_number}
                          {/* {
                      messages[contact.id]?.[messages[contact.id].length - 1]
                        ?.text
                    } */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          deleteContactAPI(contact.contact_number);
                        }}
                        className=" p-2 cursor-pointer text-center  text-white "
                        style={{
                          border: "0px solid #000",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        <img src={trash} alt="Logo" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div
          className="bg-white"
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          {loading ? (
            <div
              className=" m-5 p-5 d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <div className="loader "></div>
            </div>
          ) : (
            <>
              {selectedContactId && (
                <div className="p-4 bg-white">
                  <div
                    style={{ position: "sticky", top: 0 }}
                    className=" d-flex justify-content-between font2 text-white text-start border-radius-20 p-2 px-3 font3 font-bold-400 bg-green-filter"
                  >
                    <div className="">
                      {
                        contacts.find(
                          (contact) => contact.id === selectedContactId
                        ).contact_name
                      }
                      {" - "}
                      {
                        contacts.find(
                          (contact) => contact.id === selectedContactId
                        ).contact_number
                      }
                    </div>
                    <div>
                      <button
                        //  onClick={() => setShowModal(true)}
                        onClick={() => setSelectedContact(selectedContactId)}
                        className=" cursor-pointer text-centersss bg-green-filter text-white "
                        style={{
                          border: "0px solid #000",
                          borderRadius: "5px",
                        }}
                      >
                        {/* <img src={Edit} alt="Logo" /> */}
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="10"
                            fill="#ffff"
                            fill-opacity="1"
                          />
                          <path
                            d="M24 10H16C12 10 10 12 10 16V29C10 29.55 10.45 30 11 30H24C28 30 30 28 30 24V16C30 12 28 10 24 10Z"
                            stroke="#01C37D"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.91 15.8399L15.72 21.0299C15.52 21.2299 15.33 21.6199 15.29 21.8999L15.01 23.8799C14.91 24.5999 15.41 25.0999 16.13 24.9999L18.11 24.7199C18.39 24.6799 18.78 24.4899 18.98 24.2899L24.17 19.0999C25.06 18.2099 25.49 17.1699 24.17 15.8499C22.85 14.5199 21.81 14.9399 20.91 15.8399Z"
                            stroke="#01C37D"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.17 16.5798C20.61 18.1498 21.84 19.3898 23.42 19.8298"
                            stroke="#01C37D"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        {/* Edit */}
                      </button>

                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Email</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form onSubmit={handleEditContactSubmit}>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactName"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contactName"
                                name="contactName"
                                value={contactInfo.contactName}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactNumber"
                              >
                                Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contactNumber"
                                name="contactNumber"
                                value={contactInfo.contactNumber}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactEmail"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="contactEmail"
                                name="contactEmail"
                                value={contactInfo.contactEmail}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label style={{ width: "5rem" }} htmlFor="gender">
                                Gender
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="gender"
                                name="gender"
                                value={contactInfo.gender}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label style={{ width: "5rem" }} htmlFor="tags">
                                Tags
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="tags"
                                name="tags"
                                value={contactInfo.tags}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="d-flex my-2 justify-content-center mt-3">
                              <button
                                type="submit"
                                className="btn bg-green-filter text-center text-white  "
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="p-4 bg-white contactsList_chat"
                style={{
                  flex: 1,
                  overflowY: "auto",
                  padding: "0px",
                  backgroundImage: `url(${Chat_BG})`,
                  backgroundRepeat: "repeat",
                  backgroundPosition: "center",

                  backgroundSize: "30%",
                }}
              >
                <>
                  {/* <div className="bg-dark p-5 m-5 text-white"> hiii{chats.length}</div> */}
                  {selectedContactId ? (
                    chats.length === 0 ? (
                      <div className="text-3-2 d-flex justify-content-center align-items-center ">
                        No Chat Found !
                      </div>
                    ) : (
                      chats.map((chat) => (
                        <div
                          key={chat.message_id}
                          style={{
                            textAlign: chat.sender === "1" ? "right" : "left",
                            margin: "10px 0",
                          }}
                        >
                          <div
                            style={{ width: "20rem" }}
                            className={` message-container  ${
                              chat.sender === "1" ? "sent  " : "received"
                            }`}
                          >
                            <p
                              className={` message-text  ${
                                chat.sender === "1"
                                  ? "text-start "
                                  : "text-start"
                              }`}
                            >
                              {chat.message}
                            </p>
                            <p
                              className={` p-0 m-0  message-timestamp ${
                                chat.sender === "1" ? "text-end" : "text-end"
                              }`}
                            >
                              {chat.datetime}
                            </p>
                          </div>
                          <div ref={messagesEndRef}></div>
                        </div>
                      ))
                    )
                  ) : (
                    <p>
                      <ContectBook userID={userID} fetchData={fetchData} />

                     
                    </p>
                  )}
                </>
              </div>
            </>
          )}

          {selectedContactId && (
            <>
              <div
                className="px-4 d-flex"
                style={{ borderTop: "1px solid #ccc", padding: "10px" }}
              >
                <Form
                  style={{ width: "100%", marginRight: "2px" }}
                  onSubmit={handleSubmit}
                >
                  <FormGroup>
                    <FormControl
                      type="text"
                      className="p-2 my-2 w-100"
                      // value={messageText}
                      // onChange={(e) => setMessageText(e.target.value)}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type a message"
                    />
                  </FormGroup>
                </Form>

                <button
                  type="submit"
                  className="bg-white border-0"
                  // onClick={handleSendMessage}
                  onClick={handleSubmit}
                >
                  <img src={sendImage} height={40} alt="" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailChats;
