import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import Swal from 'sweetalert2'; 
// import beepSoundmp3 from '../src/Sound/3beeps-108353.mp3';
import beepSoundmp3 from '../Sound/3beeps-108353.mp3';
import logoImage from "../images/mini-logo.svg";
import BookImage from "../images/order.svg";
import Lead from "../images/lead.svg";
import ProfileImage from "../images/Profile.svg";
import LogoutImage from "../images/logout.svg";
import DocImage from "../images/Templates.svg";
import VolImage from "../images/Manage.svg";
import VolSetImage from "../images/Campaign.svg";
import DLTImage from "../images/Status.svg";
import VoiceImage from "../images/Chat.svg";
import SetImage from "../images/Plans.svg";
import Catalogue from "../images/catalogue.svg";
import Delivery from "../images/Delivery.svg";
import Analytics1 from "../images/Contactbook.svg";
import ContactBook from "../images/Analytics1.svg";
import Logs from "../images/Logs.svg";
import user_management from "../images/user_management.svg";
import "./DesktopNav.css";
const InternalNavBarWhatsApp = ({
  commonMinimized,
  open,
  toggleCommonMinimized,
  internalNavMinimized,
  toggleMinimizeMaximize,
  userID,
  sideBarAccessWhatsApp
}) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);
  const [sideBarAccess, setSideBarAccess] = useState([]);

  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "2",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          setSideBarAccess(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };

    fetchSideBarAccess();
  }, [userID]);

  const navLinks = sideBarAccessWhatsApp.map((service) => {
    return (
      <NavLink
      key={service.id}
      to={service.service_url}
      className={`navlink-height ${
        currentPath === service.service_url ? " internal-link  " : ""
      }`}
      exact
      style={{ textDecoration: 'none',borderRight:"3px solid #fff" }}
    >
      <div
        className={`d-flex flex-column align-items-center ${
          commonMinimized ? "my-2 mx-3" : "m-3"
        }`}
      >
        <img
          src={service.service_active_icon}
          alt={service.service_name}
          style={{ width: "20px", height: "auto" }}
        />
        {commonMinimized && (
          <div className="nav-text text-bg-green text-center single-line-css">
            {service.service_name}
          </div>
        )}
      </div>
      {!commonMinimized && service.service_name}
    </NavLink>
    );
  });

  return (
    <div className={`internal-nav ${commonMinimized ? "minimized" : ""}`}>
      <div
        className="m-0 mt-4 d-flex justify-content-end pb-4 "
        style={{ borderBottom: "1px solid #E8E2E2" }}
      >
        <button
          className="px-3 py-2"
          style={{
            fontFamily: "Nunito,sans-serif",
            border: "0px",
            borderRadius: "10px 0px 0px 10px",
         
          }}
          // onClick={() => toggleCommonMinimized(!commonMinimized)}
        >
          {commonMinimized ? "🡪" : "🡨"}
        </button>
      </div>
      <div className="nav-links2 mt-4">{navLinks}</div>
    </div>
  );
};


const InternalNavBarSMS = ({

  commonMinimized,
  open,
  toggleCommonMinimized,
  internalNavMinimized,
  toggleMinimizeMaximize,
  userID,
  sideBarAccessSMS

}) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);
  const [sideBarAccess, setSideBarAccess] = useState([]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "3",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccess(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);
  const navLinks = sideBarAccessSMS.map((service) => {
    return (
      <NavLink
      key={service.id}
      to={service.service_url}
      className={`navlink-height ${
        currentPath === service.service_url ? " internal-link  " : ""
      }`}
      exact
      style={{ textDecoration: 'none',borderRight:"3px solid #fff" }}
    >
      <div
        className={`d-flex flex-column align-items-center ${
          commonMinimized ? "my-2 mx-3" : "m-3"
        }`}
      >
        <img
          src={service.service_active_icon}
          alt={service.service_name}
          style={{ width: "20px", height: "auto" }}
        />
        {commonMinimized && (
          <div className="nav-text text-bg-green text-center single-line-css">
            {service.service_name}
          </div>
        )}
      </div>
      {!commonMinimized && service.service_name}
    </NavLink>
    );
  });
  return (
    <div className={`internal-nav ${commonMinimized ? "minimized" : ""}`}>
      <div
        className="m-0 mt-4 d-flex justify-content-end pb-4 "
        style={{ borderBottom: "1px solid #E8E2E2" }}
      >
        <button
          className="px-3 py-2"
          style={{
            fontFamily: "Nunito,sans-serif",
            border: "0px",
            borderRadius: "10px 0px 0px 10px",
          }}
          // onClick={() => toggleCommonMinimized(!commonMinimized)}
        >
          {commonMinimized ? "🡪" : "🡨"}
        </button>
      </div>
      <div className="nav-links2 mt-4">{navLinks}</div>
    </div>
  );
};
const InternalNavBarEMAIL = ({
  commonMinimized,
  open,
  toggleCommonMinimized,
  internalNavMinimized,
  toggleMinimizeMaximize,
  userID,
  sideBarAccessEMAIL
}) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);
  const [sideBarAccess, setSideBarAccess] = useState([]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "4",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          setSideBarAccess(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };

    fetchSideBarAccess();
  }, [userID]);

  const navLinks = sideBarAccessEMAIL.map((service) => {
    return (
      <NavLink
      key={service.id}
      to={service.service_url}
      className={`navlink-height ${
        currentPath === service.service_url ? " internal-link  " : ""
      }`}
      exact
      style={{ textDecoration: 'none',borderRight:"3px solid #fff" }}
    >
      <div
        className={`d-flex flex-column align-items-center ${
          commonMinimized ? "my-2 mx-3" : "m-3"
        }`}
      >
        <img
          src={service.service_active_icon}
          alt={service.service_name}
          style={{ width: "20px", height: "auto" }}
        />
        {commonMinimized && (
          <div className="nav-text text-bg-green text-center single-line-css">
            {service.service_name}
          </div>
        )}
      </div>
      {!commonMinimized && service.service_name}
    </NavLink>
    );
  });

  return (
    <div className={`internal-nav ${commonMinimized ? "minimized" : ""}`}>
      <div
        className="m-0 mt-4 d-flex justify-content-end pb-4 "
        style={{ borderBottom: "1px solid #E8E2E2" }}
      >
        <button
          className="px-3 py-2"
          style={{
            fontFamily: "Nunito,sans-serif",
            border: "0px",
            borderRadius: "10px 0px 0px 10px",
         
          }}
          // onClick={() => toggleCommonMinimized(!commonMinimized)}
        >
          {commonMinimized ? "🡪" : "🡨"}
        </button>
      </div>
      <div className="nav-links2 mt-4">{navLinks}</div>
    </div>
  );
};
const InternalNavBarBook = ({
  commonMinimized,
  toggleCommonMinimized,
  internalNavMinimized,
  toggleMinimizeMaximize,
  userID,
  sideBarAccessOrder
}) => {
  // State for current path
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  // State for sidebar access
  const [sideBarAccess, setSideBarAccess] = useState([]);

  // Fetch the sideBarAccess from the API
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "5",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          setSideBarAccess(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };

    fetchSideBarAccess();
  }, [userID]);

  // Create navigation links dynamically based on sidebar access
  const navLinks = sideBarAccessOrder.map((service) => {
    return (
      <NavLink
        key={service.id}
        to={service.service_url}
        className={`navlink-height ${
          currentPath === service.service_url ? " internal-link  " : ""
        }`}
        exact
        style={{ textDecoration: 'none',borderRight:"3px solid #fff" }}
      >
        <div
          className={`d-flex flex-column align-items-center ${
            commonMinimized ? "my-2 mx-3" : "m-3"
          }`}
        >
          <img
            src={service.service_active_icon}
            alt={service.service_name}
            style={{ width: "20px", height: "auto" }}
          />
          {commonMinimized && (
            <div className="nav-text text-bg-green text-center">
              {service.service_name}
            </div>
          )}
        </div>
        {!commonMinimized && service.service_name}
      </NavLink>
    );
  });

  return (
    <div className={`internal-nav ${commonMinimized ? "minimized" : ""}`}>
      <div
        className="m-0 mt-4 d-flex justify-content-end pb-4 "
        style={{ borderBottom: "1px solid #E8E2E2" }}
      >
        <button
          className="px-3 py-2"
          style={{
            fontFamily: "Nunito, sans-serif",
            border: "0px",
            borderRadius: "10px 0px 0px 10px",
         
          }}
          // onClick={toggleInternalNavMinimized}
        >
          {commonMinimized ? "🡪" : "🡨"}
        </button>
      </div>
      <div className="nav-links2 mt-4">{navLinks}</div>
    </div>
  );
};
const InternalNavBarAnalytics = ({
  commonMinimized,
  toggleCommonMinimized,
  internalNavMinimized,
  toggleMinimizeMaximize,
  userID,
  sideBarAccessAnalytics
}) => {
  const toggleInternalNavMinimized = () => {
    //setMinimized(!minimized);
    toggleCommonMinimized(!commonMinimized);
    toggleMinimizeMaximize(!internalNavMinimized);
  };
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);


  const [sideBarAccess, setSideBarAccess] = useState([]);

  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "7",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          setSideBarAccess(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };

    fetchSideBarAccess();
  }, [userID]);

  const navLinks = sideBarAccessAnalytics.map((service) => {
    return (
      <NavLink
        key={service.id}
        // to={navLinkPath}
           to={service.service_url}
           className={`navlink-height ${
            currentPath === service.service_url ? " internal-link  " : ""
          }`}
          exact
          style={{ textDecoration: 'none',borderRight:"3px solid #fff" }}
      >
        <div
          className={`d-flex flex-column align-items-center ${
            commonMinimized ? "my-2 mx-3" : "m-3"
          }`}
        >
          <img
            src={service.service_active_icon }
            alt={service.service_name}
            style={{ width: "20px", height: "auto" }}
          />
          {commonMinimized && (
            <div className="nav-text text-bg-green text-center">
              {service.service_name}
            </div>
          )}
        </div>
        {!commonMinimized && service.service_name}
      </NavLink>
    );
  });



  return (
    <div className={`internal-nav ${commonMinimized ? "minimized" : ""}`}>
      <div
        className="m-0 mt-4 d-flex justify-content-end pb-4 "
        style={{ borderBottom: "1px solid #E8E2E2" }}
      >
        <button
          className="px-3 py-2"
          style={{
            fontFamily: "Nunito,sans-serif",
            border: "0px",
            borderRadius: "10px 0px 0px 10px",
         
          }}
          // onClick={() => toggleCommonMinimized(!commonMinimized)}
        >
          {commonMinimized ? "🡪" : "🡨"}
        </button>
      </div>
      <div className="nav-links2 mt-4">{navLinks}</div>
    </div>
  );
};
const InternalNavBarProfile = ({
  commonMinimized,
  toggleCommonMinimized,
  internalNavMinimized,
  toggleMinimizeMaximize,
  userID,
  sideBarAccessProfile
}) => {
  // const [minimized, setMinimized] = useState(true);

  const toggleInternalNavMinimized = () => {
    //setMinimized(!minimized);
    toggleCommonMinimized(!commonMinimized);
    toggleMinimizeMaximize(!internalNavMinimized);
  };
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);


  const [sideBarAccess, setSideBarAccess] = useState([]);




  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "9",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          setSideBarAccess(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };

    fetchSideBarAccess();
  }, [userID]);




  const navLinks = sideBarAccessProfile.map((service) => {
    return (
      <NavLink
        key={service.id}
        // to={navLinkPath}
        to={service.service_url}
        className={`navlink-height ${
          currentPath === service.service_url ? " internal-link  " : ""
        }`}
        exact
        style={{ textDecoration: 'none',borderRight:"3px solid #fff" }}
      >
        <div
          className={`d-flex flex-column align-items-center ${
            commonMinimized ? "my-2 mx-3" : "m-3"
          }`}
        >
          <img
            src={service.service_active_icon }
            alt={service.service_name}
            style={{ width: "20px", height: "auto" }}
          />
          {commonMinimized && (
            <div className="nav-text text-bg-green text-center">
              {service.service_name}
            </div>
          )}
        </div>
        {!commonMinimized && service.service_name}
      </NavLink>
    );
  });

  return (
    <div className={`internal-nav ${commonMinimized ? "minimized" : ""}`}>
    <div
      className="m-0 mt-4 d-flex justify-content-end pb-4 "
      style={{ borderBottom: "1px solid #E8E2E2" }}
    >
      <button
        className="px-3 py-2"
        style={{
          fontFamily: "Nunito,sans-serif",
          border: "0px",
          borderRadius: "10px 0px 0px 10px",
       
        }}
        // onClick={() => toggleCommonMinimized(!commonMinimized)}
      >
        {commonMinimized ? "🡪" : "🡨"}
      </button>
    </div>
    <div className="nav-links2 mt-4">{navLinks}</div>
  </div>
  );
};
const InternalNavBarFacebook = ({
  commonMinimized,
  toggleCommonMinimized,
  internalNavMinimized,
  toggleMinimizeMaximize,
  userID,
  sideBarAccessFacebook
}) => {
  // const [minimized, setMinimized] = useState(true);

  const toggleInternalNavMinimized = () => {
    //setMinimized(!minimized);
    toggleCommonMinimized(!commonMinimized);
    toggleMinimizeMaximize(!internalNavMinimized);
  };
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);


  const [sideBarAccess, setSideBarAccess] = useState([]);

  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "5",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );

        if (response.data.responseCode === "00") {
          setSideBarAccess(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };

    fetchSideBarAccess();
  }, [userID]);

  const navLinks = sideBarAccessFacebook.map((service) => {
    return (
      <NavLink
        key={service.id}
        // to={navLinkPath}
        to={service.service_url}
        className={`navlink-height ${
          currentPath === service.service_url ? " internal-link  " : ""
        }`}
        exact
        style={{ textDecoration: 'none',borderRight:"3px solid #fff" }}
      >
        <div
          className={`d-flex flex-column align-items-center ${
            commonMinimized ? "my-2 mx-3" : "m-3"
          }`}
        >
          <img
            src={service.service_active_icon }
            alt={service.service_name}
            style={{ width: "20px", height: "auto" }}
          />
          {commonMinimized && (
            <div className="nav-text text-bg-green text-center single-line-css">
              {service.service_name}
            </div>
          )}
        </div>
        {!commonMinimized && service.service_name}
      </NavLink>
    );
  });





  return (
    <div className={`internal-nav ${commonMinimized ? "minimized" : ""}`}>
    <div
      className="m-0 mt-4 d-flex justify-content-end pb-4 "
      style={{ borderBottom: "1px solid #E8E2E2" }}
    >
      <button
        className="px-3 py-2"
        style={{
          fontFamily: "Nunito,sans-serif",
          border: "0px",
          borderRadius: "10px 0px 0px 10px",
       
        }}
        // onClick={() => toggleCommonMinimized(!commonMinimized)}
      >
        {commonMinimized ? "🡪" : "🡨"}
      </button>
    </div>
    <div className="nav-links2 mt-4">{navLinks}</div>
  </div>
  );
};
const NavigationBar = ({ setUser, handleLogout, userID }) => {
  const location = useLocation(); 
  const [showInternalNav, setShowInternalNav] = useState(() => {
    const storedPower = sessionStorage.getItem("power");
    if (storedPower === "1") {
      // return null;
      const storedValue = sessionStorage.getItem("showInternalNav");
      return storedValue ? JSON.parse(storedValue) : "";
    } else {
      const storedValue = sessionStorage.getItem("showInternalNav");
      return storedValue ? JSON.parse(storedValue) : "";
    }
  });
  const [sideBarAccess, setSideBarAccess] = useState([]);
  const sideBarAccessApi = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        userid: userID,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Entitlement/fetchEntitlementModuleDetails",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        setSideBarAccess(response.data.data);
      } else {
      }
    } catch {}
  };
  useEffect(() => {
    sideBarAccessApi();
  }, []);
  // Update session storage whenever showInternalNav changes
  useEffect(() => {
    sessionStorage.setItem("showInternalNav", JSON.stringify(showInternalNav));
  }, [showInternalNav]);
  // Log session storage on each render for debugging
  useEffect(() => {
    console.log("Session Storage:", sessionStorage.getItem("showInternalNav"));
  }, []);
  const [internalNavMinimized, setInternalNavMinimized] = useState(
    () => JSON.parse(sessionStorage.getItem("internalNavMinimized")) ?? true
  );
  const [commonMinimized, setCommonMinimized] = useState(
    () => JSON.parse(sessionStorage.getItem("commonMinimized")) ?? true
  );
  // Effect to update sessionStorage when state changes
  useEffect(() => {
    sessionStorage.setItem("showInternalNav", JSON.stringify(showInternalNav));
  }, [showInternalNav]);
  useEffect(() => {
    sessionStorage.setItem(
      "internalNavMinimized",
      JSON.stringify(internalNavMinimized)
    );
  }, [internalNavMinimized]);
  useEffect(() => {
    sessionStorage.setItem("commonMinimized", JSON.stringify(commonMinimized));
  }, [commonMinimized]);
  const toggleInternalNav = (type) => {
    if (showInternalNav === type) {
      //setShowInternalNav(null);
      // setInternalNavMinimized(!internalNavMinimized);
    } else {
      setShowInternalNav(type);
    }
  };
  const toggleMinimizeMaximize = () => {
    setInternalNavMinimized(!internalNavMinimized);
  };
  const toggleCommonMinimized = () => {
    setCommonMinimized(!commonMinimized);
  };
  // const handleLogout = () => {
  //   setUser(false);
  //   sessionStorage.removeItem('user');
  //   // logout();
  // };
  const menuItems = JSON.parse(sessionStorage.getItem("menuItems")) || [];
  const [sideBarAccessWhatsApp, setSideBarAccessOrderWhatsApp] = useState([]);
  const [sideBarAccessOrder, setSideBarAccessOrder] = useState([]);
  const [sideBarAccessAnalytics, setSideBarAccessOrderAnalytics] = useState([]);
  const [sideBarAccessProfile, setSideBarAccessOrderProfile] = useState([]);
  const [sideBarAccessFacebook, setSideBarAccessFacebook ]= useState([]);
  const [sideBarAccessSMS, setSideBarAccessSMS ]= useState([]);

  const [sideBarAccessEMAIL, setSideBarAccessEMAIL ]= useState([]);

  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "2",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccessOrderWhatsApp(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "6",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccessOrder(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "7",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccessOrderAnalytics(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "9",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccessOrderProfile(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "5",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccessFacebook(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "4",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccessEMAIL(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);
  useEffect(() => {
    const fetchSideBarAccess = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          moduleId: "3",
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Entitlement/fetchEntitlementServiceDetails",
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          setSideBarAccessSMS(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching sideBarAccess", error);
      }
    };
    fetchSideBarAccess();
  }, [userID]);

  const audioRef = useRef(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false); 
  const [orders, setOrders] = useState([]); // Stores all orders
  const [newOrderCount, setNewOrderCount] = useState(0); // Count of total new orders
  const [processedOrders, setProcessedOrders] = useState(() => {
    // Get the processed orders from localStorage, or an empty array if none exist
    const savedOrders = localStorage.getItem('processedOrders');
    return savedOrders ? JSON.parse(savedOrders) : [];
  });
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [beepSound, setBeepSound] = useState(null);
  useEffect(() => {
    // Create and load the audio object when the component mounts
    const sound = new Audio(beepSoundmp3);
    sound.loop = true;
    // Ensure the audio is fully loaded before assigning it to state
    sound.oncanplaythrough = () => {
      setBeepSound(sound); // Only set the state when the sound is ready
    };
    return () => {
      sound.pause(); // Cleanup: pause sound when the component unmounts
    };
  }, []); // Empty dependency array ensures this runs once
  const playBeepSound = () => {
    if (beepSound) {
      beepSound.play().catch(error => {
        if (error.name === 'NotAllowedError') {
          console.error("Browser blocked autoplay due to lack of user interaction.");
        }
      });
    }
    return Promise.resolve();
  };
  const stopBeepSound = () => {
    if (beepSound) {
      beepSound.pause();
      beepSound.currentTime = 0; 
    }
  };
  const getNewOrder = async () => {
    try {
      const currentDate = getCurrentDate(); // Implement your logic for date
      const oneWeekBackDate = getCurrentDate(); // Implement your logic for date

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Example token
        },
      };
      const requestBody = {
        userid: userID,
        secret: "string",
        fromdate: oneWeekBackDate,
        todate: currentDate,
        status: ["received"],
      };

      const response = await axios.post(
        "https://api.anlook.com/api/Whatsapp/getCatalogOrders",
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        const fetchedOrders = response.data.response;
  
        // Filter new unprocessed orders
        const newOrders = fetchedOrders.filter(order => !processedOrders.includes(order.orderid));
  
        if (newOrders.length > 0) {
          // Play beep sound right before Swal opens
         await playBeepSound();
  
          Swal.fire({
            title: 'New Order Received!',
            text: `Order ID: ${newOrders[0].daily_order_id} has arrived.`,
            icon: 'info',
            confirmButtonText: 'Okay'
          }).then(() => {
            // Stop the sound when Swal is closed
            stopBeepSound();
          });
  
          // Mark new orders as processed
          const newProcessedOrderIds = newOrders.map(order => order.orderid);
          const updatedProcessedOrders = [...processedOrders, ...newProcessedOrderIds];
  
          // Update processed orders in state and localStorage
          setProcessedOrders(updatedProcessedOrders);
          localStorage.setItem('processedOrders', JSON.stringify(updatedProcessedOrders));
        }
  
        setOrders(fetchedOrders);
      } else {
        console.error("Failed to fetch orders:", response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const interval = setInterval(getNewOrder, 10000); // Poll every 10 seconds
    return () => clearInterval(interval);
  }, [processedOrders,userID]);
  return (
    <>
      <div
        className={`sidebar py-5  h-100 ${
          showInternalNav ? "internal-nav-open" : ""
        }`}
      >
        <div>
          <div className="logo d-flex justify-content-center ">
            <img src={logoImage} height={50} alt="Logo" />
          </div>
          <div className="d-flex flex-column justify-content-start">
            {sideBarAccess.map((module) => (
              <div className="nav-link-container p-0 m-0" key={module.id}>
                <NavLink
                  to={module.module_url}
                  className="link navlink-height d-flex flex-column"
                  onClick={(e) => {
                    if (module.status !== "Y") {
                      e.preventDefault(); 
                    }else{
                      toggleInternalNav(module.module_name);
                    }
                  }}
                >
                  <img
                    src={
                      module.status === "Y"
                        ? module.module_active_icon
                        : module.module_inactive_icon
                    }
                    alt={module.module_name}
                    height={20}
                    width={20}
                    style={{
                      display:
                        module.status === "Y" &&
                        window.location.pathname.startsWith(module.module_url)
                          ? "none"
                          : "block",
                    }}
                  />
                  <img
                    src={module.module_selected_icon} 
                    alt="Selected Icon"
                    height={20}
                    width={20}
                    style={{
                      display:
                        module.status === "Y" &&
                        window.location.pathname.startsWith(module.module_url)
                          ? "block"
                          : "none",
                    }}
                  />
                  <div className="nav-text">{module.module_name}</div>
                </NavLink>
                { window.location.pathname.startsWith("/Dashboard")  && (
                  <>
                  </>
              )}
                {window.location.pathname.startsWith("/WhatsApp") && (
                <InternalNavBarWhatsApp
                  open={1}
                  toggleCommonMinimized={toggleCommonMinimized}
                  commonMinimized={commonMinimized}
                  internalNavMinimized={internalNavMinimized}
                  toggleMinimizeMaximize={toggleMinimizeMaximize}
                  userID={userID}
                  sideBarAccessWhatsApp={sideBarAccessWhatsApp}
                />
              )}
               {window.location.pathname.startsWith("/Orders")  && (
                <InternalNavBarBook
                  toggleCommonMinimized={toggleCommonMinimized}
                  commonMinimized={commonMinimized}
                  internalNavMinimized={internalNavMinimized}
                  toggleMinimizeMaximize={toggleMinimizeMaximize}
                  userID={userID}
                  sideBarAccessOrder={sideBarAccessOrder}
                />
              )}
                {window.location.pathname.startsWith("/Analytics")  && (
    <InternalNavBarAnalytics
      toggleCommonMinimized={toggleCommonMinimized}
      commonMinimized={commonMinimized}
      internalNavMinimized={internalNavMinimized}
      toggleMinimizeMaximize={toggleMinimizeMaximize}
      userID={userID}
      sideBarAccessAnalytics={sideBarAccessAnalytics}
    />
  )}
   {window.location.pathname.startsWith("/Profile")  && (
            <InternalNavBarProfile
              toggleCommonMinimized={toggleCommonMinimized}
              commonMinimized={commonMinimized}
              internalNavMinimized={internalNavMinimized}
              toggleMinimizeMaximize={toggleMinimizeMaximize}
              userID={userID}
              sideBarAccessProfile={sideBarAccessProfile}
            />
          )}
           {window.location.pathname.startsWith("/facebook")  && (
            <InternalNavBarFacebook
              toggleCommonMinimized={toggleCommonMinimized}
              commonMinimized={commonMinimized}
              internalNavMinimized={internalNavMinimized}
              toggleMinimizeMaximize={toggleMinimizeMaximize}
              userID={userID}
              sideBarAccessFacebook={sideBarAccessFacebook}
            />
          )}
           {window.location.pathname.startsWith("/Sms")  && (
            <InternalNavBarSMS
              toggleCommonMinimized={toggleCommonMinimized}
              commonMinimized={commonMinimized}
              internalNavMinimized={internalNavMinimized}
              toggleMinimizeMaximize={toggleMinimizeMaximize}
              userID={userID}
              sideBarAccessSMS={sideBarAccessSMS}
            />
          )}
           {window.location.pathname.startsWith("/Email")  && (
            <InternalNavBarEMAIL
              toggleCommonMinimized={toggleCommonMinimized}
              commonMinimized={commonMinimized}
              internalNavMinimized={internalNavMinimized}
              toggleMinimizeMaximize={toggleMinimizeMaximize}
              userID={userID}
              sideBarAccessEMAIL={sideBarAccessEMAIL}
            />
          )}
              </div>
            ))}
          </div>
        </div>
        <div className="w-100">
          <div
            className="link cursor-pointer d-flex flex-column"
            onClick={handleLogout}
          >
            <img
              src={LogoutImage}
              alt="Logout"
              style={{ width: "20px", height: "auto" }}
            />
            <div className="nav-text">
              Logout
              </div>
          </div>
        </div>
      </div>
      <div className="">
      </div>
      <div
        className={`content-nav ${
          showInternalNav ? "content-internal-nav-open" : " "
        } ${internalNavMinimized ? "content-internal-nav-open-minimized" : ""}`}
      >
        {/* Content components go here */}
      </div>
    </>
  );
};
export default NavigationBar;