import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate ,useParams} from "react-router-dom";
import arrowDown from "../../../images/arrow-down.svg";

import NoIndex from "../../NoIndex";
import "../AdminDashboard.css"

const AdminCampaignView = ({ userID }) => {
    const { clientId } = useParams();
    const { campaignId} = useParams();
    console.log(campaignId)
    
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [CampaignId, setCampaignId] = useState("");
  const [campaignName, setCampaignName] = useState("");

  // Hook to get the current URL location
  const location = useLocation();

  // Function to extract query parameters from the URL
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("campaignId");
    const name = searchParams.get("campaignName");
    setCampaignId(id);
    setCampaignName(name);
  };

  // Fetch query parameters on component mount
  useEffect(() => {
    getQueryParams();
  }, [location.search]); // Re-run the effect when the search query changes

  const handleEditClick = (campaignId, campaignName) => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("campaignId");
    const name = searchParams.get("campaignName");
    console.log("Clicked row with campaignId:", id);
    console.log("Clicked row with campaignName:", name);
    // navigate(`/Dashboard`);

    navigate(`/CampaignDetails?campaignId=${id}&campaignName=${name}`);
  };

  const [Campaign, setCampaign] = useState([]);

  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const getCampaign = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: clientId,
        campaignName: "string",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.anlook.com/api/Campaign/getCampaigns`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setCampaign(data?.data);
        console.log(data?.data)
      } else {
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getCampaign();
  }, []);

  return (
    <div className="marginRight">
      <NoIndex />
      <div
        className="p-4 bg-white "
        style={{
          height: "100vh",
          fontFamily: "Nunito,sans-serif",
        }}
      >
        <div className=" px-4  justify-content-center">
          <div className="d-flex justify-content-between">
            <p className="CreateCampaign">Campaign Details </p>
            <p>
              {" "}
              <button
                // style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                className="blue-button p-2 px-4 me-3 text-white"
                onClick={() => handleEditClick()}
              >
                Edit
              </button>
            </p>
          </div>
          <div className=" px-2">
            <div
              class="p-4 row col-12"
              style={{
                borderRadius: "10px",
                border: "1px solid #ccc",
                // backgroundColor: "rgba(1, 195, 125, 0.05)",
              }}
            >
              {Campaign.map((item) => (
                <>
                  {item.id == campaignId ? (
                    <div>
                      {
                        <>
                          <div className="campaign-detail-info">
                            <div
                              className="campaign-detail-header"
                              onClick={toggleDropdown}
                            >
                              Campaign Info
                              <span
                                className={`campaign-detail-arrow ${
                                  isOpen ? "campaign-detail-open" : ""
                                }`}
                              >
                                <img src={arrowDown} />
                              </span>
                            </div>
                            <div
                              className={`campaign-detail-dropdown-content ${
                                isOpen ? "campaign-detail-open" : ""
                              }`}
                            >
                              <div
                                className="bg-white p-3 d-flex"
                                style={{ borderRadius: "10px" }}
                              >
                                <div>
                                  <div className="campaignBoxName d-flex py-3">
                                    <>
                                      <b>Campaign Name: </b>{" "}
                                      {item.campaign_name}{" "}
                                    </>
                                  </div>
                                  <div className="campaignBoxName d-flex py-3">
                                    <>
                                      <b>Start Date:</b> {item.campaign_start}{" "}
                                    </>
                                  </div>
                                  <div className="campaignBoxName d-flex py-3">
                                    <>
                                      <b>End Date:</b> {item.campaign_end}{" "}
                                    </>
                                  </div>{" "}
                                </div>
                                <div className="ps-5">
                                  <div className="campaignBoxName d-flex py-3">
                                    <>
                                      <b>Interval Time:</b>{" "}
                                      {item.campaign_interval}{" "}
                                    </>
                                  </div>
                                  <div className="campaignBoxName d-flex py-3">
                                    <>
                                      <b>Channel:</b>
                                      {item.channel}{" "}
                                    </>
                                  </div>
                                  <div className="campaignBoxName d-flex py-3">
                                    <>
                                      <b>Template in use:</b> {item.template_id}{" "}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr className="text-3-2" />
                          <p className="campaignheading my-3">
                            Selected Audience
                          </p>

                          <div
                            className="bg-danger "
                            style={{ borderRadius: "10px" }}
                          >
                            <table class="table table-bordered my-3">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ background: "#F6F8FE" }}
                                  >
                                    Contact ID
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ background: "#F6F8FE" }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ background: "#F6F8FE" }}
                                  >
                                    Number
                                  </th>
                                </tr>
                              </thead>
                              <tbody style={{ borderRadius: "10px" }}>
                                {item.members?.map((user) => (
                                  <>
                                    <tr style={{ borderRadius: "10px" }}>
                                      <th scope="row">{user.member_id}</th>

                                      <td>{user.name}</td>
                                      <td>{user.mobile}</td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCampaignView;