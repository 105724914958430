import React, { useState, useEffect } from "react";
import axios from "axios";

const AllPost = () => {
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userID, setUserID] = useState("1");
  const [isExpanded, setIsExpanded] = useState(false);
  const BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [userInfo, setUserInfo] = useState(null); 
  const [userData, setUserData] = useState(null);
  const [url, setUrl] = useState("");
  const [showPageSelection, setShowPageSelection] = useState(false);
  const [pages, setPages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); 
  const [loadingPages, setLoadingPages] = useState(false); 
  const [selectedPage, setSelectedPage] = useState(null); 
  const [posts, setPosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(false);

  const handleFacebookLogin = () => {
    window.location.href = "https://goseo.in/facebook/facebook_oauth.php";
  };

  const handleLogout = () => {
    sessionStorage.removeItem("userInfo");
    setIsLoggedIn(false);
    setUserInfo(null);
  };

  const handleSelectPageClick = () => {
    setLoadingPages(true);
    axios
      .post('https://api.goseo.in/api/Facebook/GetFacebookPages', { userid: 'a1001' })
      .then((response) => {
        const fetchedPages = response.data.data;
        setPages(fetchedPages);
        setLoadingPages(false);
        if (fetchedPages.length > 0) {
          setSelectedPage(fetchedPages[0]);
        }
      })
      .catch((error) => {
        setErrorMessage('Failed to fetch pages. Please try again.');
        setLoadingPages(false);
      });
  };

  const fetchPostsForPage = (pageId, accessToken) => {
    setLoadingPosts(true);
    axios
      .post('https://api.goseo.in/api/Facebook/GetAllPageFeed', {
        pageid: pageId,
        page_access_token: accessToken,
      })
      .then((response) => {
        const fetchedPosts = response.data.data;
        setPosts(fetchedPosts);
        setLoadingPosts(false);
      })
      .catch((error) => {
        setErrorMessage('Failed to fetch posts. Please try again.');
        setLoadingPosts(false);
      });
  };

  useEffect(() => {
    handleSelectPageClick();
  }, []);

  useEffect(() => {
    if (selectedPage) {
      fetchPostsForPage(selectedPage.id, selectedPage.access_token);
    }
  }, [selectedPage]);

  return (
    <>
      <div
      style={{
        backgroundColor: "#ddd",
        color: "#000",
      }}
    >
      {/* {!isLoggedIn && (
        <div className="bg-light-green py-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="p-0 m-0">To continue with these actions, please connect your Facebook page.</p>
          <button
          className="blue-button ms-3"
            onClick={handleFacebookLogin}
            style={{
              cursor: "pointer",
            }}
          >
            Login
          </button>
        </div>
      ) } */}
    </div>
      {isLoggedIn && (
          <div className="CreateCampaign d-flex justify-content-center align-itmes-center">
           <div className="feedpost-login-message">
  <p>Please log in with Facebook to access all your posts.</p>
</div>
          
          </div>
        )}
      {!isLoggedIn && (
      <div className="feedpost-content m-4 p-4">
      <div className="feedpost-header-2 d-flex justify-content-between align-items-center">
  <h2 className="CreateCampaign">Facebook Page Posts</h2>

  {loadingPages ? (
    <p className="feedpost-loading">Loading pages...</p>
  ) : errorMessage ? (
    <p className="feedpost-error">{errorMessage}</p>
  ) : (
    <div className="feedpost-page-selection">
      {pages.length > 0 ? (
        <select
          className="feedpost-select"
          onChange={(e) => {
            const selectedPage = pages.find((page) => page.id === e.target.value);
            setSelectedPage(selectedPage);
          }}
          value={selectedPage?.id || ''}
        >
          {pages.map((page) => (
            <option key={page.id} value={page.id}>
              {page.name}
            </option>
          ))}
        </select>
      ) : (
        <p>No pages available.</p>
      )}
    </div>
  )}
</div>
        {loadingPosts ? (
          <p>Loading posts...</p>
        ) : posts.length > 0 ? (
          <table className="feedpost-table">
            <thead>
              <tr className="feedpost-table-header">
                <th className="feedpost-table-header-cell">S.No.</th>
                <th className="feedpost-table-header-cell">Created Time</th>
                <th className="feedpost-table-header-cell">Message</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post, index) => (
                <tr key={post.id} className="feedpost-table-row">
                  <td className="feedpost-table-cell p-4">{index + 1}</td>
                  <td className="feedpost-table-cell p-4">
                    {new Date(post.created_time).toLocaleString()}
                  </td>
                  <td className="feedpost-table-cell p-4">{post.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No posts available.</p>
        )}
      </div>
         )}
    </>
  );
};

export default AllPost;
