import callImage from "../../images/LandingPageImage/cta_img_1x.webp";


const CTA=()=>{

return(
<>
<div className="d-flex justify-content-center bg-light-green-2 py-5  ">
        <div className="row col-12  bg-white border-redius-20 responsive-container">
          <div className="col-12 col-lg-7  d-flex justify-content-center  align-items-center">
            <div className="p-4">
              <div className="text-5-2">Maximize your Advertising ROI,
              </div>
              <div className="text-5-2">
              Transform Clicks into Captivating Conversations!
              </div>

              <div className="py-2">
              Unleash Quality Leads, Nurture, and Interact through WhatsApp after Clicks!

              </div>

              {/* <div className="pt-4">
                <button
                  className="  btn bg-green-filter  p-2 px-4 me-5 text-white d-flex align-items-center"
                  onClick={() => {
                    window.open(
                      "https://wa.me/message/HI7RNT6ZMMT4P1"
                    );
                  }}
                >
                   Talk to an Expert
                   <img loading="lazy" src={signup} className="ps-2" alt="signup" />
             
                </button>
              </div> */}
            </div>
          </div>
          <div className="col-lg-5 col-12 m-0 p-0 d-flex justify-content-center align-items-center">
  <img
    loading="lazy"
    src={callImage}
    alt="group9"
    style={{ objectFit: 'cover', width: '70%'}}
    className=""
  />
</div>
        </div>
      </div>

</>


    );
}

export default CTA;