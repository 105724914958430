import React from "react";
import signup from "../../images/signup.svg";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import Anlook from "../../images/Anlook.svg";
import { Link } from "react-router-dom";

import indiaflag from "../../images/LandingPageImage/India.svg";
import usaflag from "../../images/LandingPageImage/USA.svg";

import FacebookLogin from "../Meta_Login/FacebookLogin";

const LandingNav = ({onSignUpClick, onLoginClick , country, handleCountryChange}) => {
  return (
    <>
      <div className=" padding-5-rem row col-12 m-0">
        <Navbar bg="" className=" d-flex align-items-between" expand="lg">
            <Navbar.Brand href="/" className=" p-0 m-0">
              <img
                src={Anlook}
                width="150"
              
                className="d-inline-block align-top "
                alt="Anlook logo - Your trusted partner in automation"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto  me-auto ">
                <Link
                  to="/"
                  className={`text-3-2 px-lg-3 p-lg-2 mx-lg-3 px-0 p-2 mx-0  ${
                    window.location.pathname === "/" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Home
                </Link>
                <Link
                  to="/pricing"
                  className={`text-3-2 p-lg-2 mx-lg-3 px-0 p-2 mx-0 ${
                    window.location.pathname === "/pricing" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Pricing
                </Link>

                <Link
                  to="/blogs"
                  className={`text-3-2 p-lg-2 mx-lg-3 px-0 p-2 mx-0 ${
                    window.location.pathname === "/blogs" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Blogs
                </Link>
                <Link
                  to="/contact"
                  className={`text-3-2 p-lg-2 mx-lg-3 px-0 p-2 mx-0 ${
                    window.location.pathname === "/contact" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Contact Us
                </Link>
              </Nav>
              <Nav>
                <div className="home-nav-responsive-flex ">
        {/* Country Dropdown */}
        {country &&
        (
          <div className="dropdown">
          <button
            className="btn btn-light dropdown-toggle d-flex align-items-center"
            type="button"
            id="countryDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={
                country === "IN"
                  ? indiaflag
                  : country === "US"
                  ? usaflag
                  : "/path-to-default-flag.png"
              }
              alt="Country Flag"
              width="24"
              height="16"
              className="me-2"
            />
            {country === "IN" ? "India" : country === "US" ? "United States" : "Other"}
          </button>
          <ul className="dropdown-menu" aria-labelledby="countryDropdown">
          
            <li>
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() => handleCountryChange("IN")}
              >
                <img
                  src={indiaflag}
                  alt="India Flag"
                  width="24"
                  height="16"
                  className="me-2"
                />
                India
              </button>
            </li>
            <li>
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() => handleCountryChange("US")}
              >
                <img
                  src={usaflag}
                  alt="US Flag"
                  width="24"
                  height="16"
                  className="me-2"
                />
                United States
              </button>
            </li>
          </ul>
        </div>

        )
        }
       





            
                  <button
                    style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className="  btn  p-2 px-4  text-white"
                    onClick={onSignUpClick}
                  >
                    Sign Up
                    <img src={signup} className="ps-2" alt="" />
                  </button>
                  <button
                    // style={{
                    //   backgroundColor: "#c4d45",
                    //   color: "rgba(1, 195, 125, 1)",
                    // }}
                    // style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className="  btn  bg-success text-white p-2 px-5 "
                    onClick={onLoginClick}
                  >
                    Login
                  </button>
    {/* <FacebookLogin/> */}
           
                </div>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default LandingNav;
