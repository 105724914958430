import React,{useState,useEffect} from "react";
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
import { Helmet } from 'react-helmet';

const Cancellation = ({onSignUpClick, onLoginClick }) => {
  const [pageData, setPageData] = useState(null);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "59");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
  }, []);

  return (
    <>



<Helmet>
        <title>Anlook Cancellation Policy - Hassle-Free Service Management</title>
        <meta name="description" content="Understand Anlook's cancellation policy for our WhatsApp automation tool. Learn about the process, conditions, and any applicable fees for cancelling your subscription. Manage your service with ease." />
        <meta name="keywords" content="Anlook cancellation policy, service cancellation, subscription management, cancellation process, cancellation terms, Anlook subscription, service termination, cancellation fees, WhatsApp automation" />

        {/* Professional schema markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Cancellation Policy",
              "url": "https://www.anlook.com/Cancellation",
              "description": "Understand Anlook's cancellation policy for our WhatsApp automation tool. Learn about the process, conditions, and any applicable fees for cancelling your subscription.",
              "mainEntity": {
                "@type": "Organization",
                "name": "Anlook",
                "url": "https://www.anlook.com",
                "logo": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp"
                
              },
              "mainContentOfPage": {
                "@type": "WebPageElement",
                "headline": "Anlook's Return/ Refund/ Cancellation Policy",
                "text": "We only offer digital products, so refunds, cancellations, or returns are not possible after an order has been placed. You can cancel a subscription anytime with no charges deducted after cancellation. For other questions, email us at support@anlook.com."
              }
            }
          `}
        </script>
      </Helmet>
      {/* //shivani */}
<Helmet>
        <title>
          {pageData ? pageData.basic_settings.tittle : "Loading..."}
        </title>
        <meta
          name="description"
          content={pageData ? pageData.basic_settings.meta_desc : "Loading..."}
        />
        <meta
          name="keywords"
          content={
            pageData
              ? pageData.keyword_settings
                  .map((keyword) => keyword.keyword)
                  .join(", ")
              : "Loading..."
          }
        />
        <link
          rel="canonical"
          href={pageData ? pageData.basic_settings.canonical_tag : "#"}
        />
        <meta
          property="og:title"
          content={
            pageData
              ? pageData.og_settings?.og_title || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:description"
          content={
            pageData
              ? pageData.og_settings?.og_desc || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:image"
          content={pageData ? pageData.og_settings?.og_image || "#" : "#"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
        {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}

      </Helmet>



      <div className=" landing-body-bg">
        <LandingNav onSignUpClick={onSignUpClick} onLoginClick={onLoginClick} />
        <div className="padding-5-rem " style={{height:"100vh"}}>
          <div className="row">
            <div className="col-12">
              <div className=" border-radius-20 pb-5 ">
                <div className="text-2-2  py-4 pb-5 ">Return/ Refund/ Cancellation Policy</div>
                <div className="">
                  <p>
                  We only offer digital products, so refunds, cancellations, or returns are not possible after an order has been placed. If you have a continuing subscription, you can cancel anytime, and no charges will be deducted after you cancel the subscription or membership. However, any charges deducted before cancellation of membership cannot be refunded.
                  </p>
                  <p className="text-3-2">
                  For any other questions regarding cancellation or refunds, please send us an email at
                   <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@anlook.com" target="_blank" rel="noopener noreferrer">
   {" "} support@anlook.com
  </a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};
export default Cancellation;
