import React, { useState, useRef , useEffect } from "react";
import { Helmet } from "react-helmet";
import LandingNav from "../Landing_Nav";
import group9 from "../../../images/LandingPageImage/web_hero_section_1x.webp";
import callImage from "../../../images/LandingPageImage/cta_img_1x.webp";
import herobg from "../../../images/LandingPageImage/hero-bg.webp";
import { ToastContainer } from "react-toastify";
import signup from "../../../images/signup.svg";
import ExternalChatBot from "./ExternalChatBot";
import CTA from "../Landing_CTA";
import sn1 from "../../../images/LandingPageImage/sn1.svg";
import sn2 from "../../../images/LandingPageImage/sn2.svg";
import sn3 from "../../../images/LandingPageImage/sn3.svg";
import Integrations from "../../../images/Integrations.svg";
import Highscalibility from "../../../images/Highscalibility.svg";
import Chat22 from "../../../images/Chat22.svg";
import TickShield from "../../../images/TickShield.svg";
import CrossChannel from "../../../images/CrossChannel.svg";
import scroll from "../../../images/scroll.svg";
import team from "../../../images/team.svg";
import tick from "../../../images/tick.svg";
import LandingFooter from "../Landing_Footer";
import ChatBot from "./Chat_Bot";
import SecondSectionFeatures from "./Second_Features";
import TabSection from "./Tab_Section";
import BlogSection from "./Blog_section";
import FAQSection from "../FAQ_Section";
import FacebookLogin from "../../Meta_Login/FacebookLogin";



const Home = ({onSignUpClick, onLoginClick ,country,handleCountryChange}) => {
  const [pageData, setPageData] = useState(null);
  const faqRef = useRef(null); // Create a ref
  const [chatBotLoaded, setChatBotLoaded] = useState(false);
  const handleChatBotOpen = () => {
    setChatBotLoaded(true); // Mark chatbot as loaded
  };





  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "52");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
  }, []);








  const scrollToFAQ = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to the FAQ section
    }
  };
  return (
    <div className="landing-body-bg ">
   <Helmet>
  <title>Anlook - WhatsApp Automation for Businesses</title>
  <link rel="preload" href={group9.src} as="image" />
  {/* <meta
    name="description"
    content="Explore Anlook's WhatsApp automation features, integrations, and scalable solutions for businesses."
  /> */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Anlook - WhatsApp Automation for Businesses",
      "url": "https://www.anlook.com/",
      "description": "Explore Anlook's WhatsApp automation features, integrations, and scalable solutions for businesses. Unlock the potential of WhatsApp & SMS marketing for seamless customer engagement and growth.",
      "mainEntity": {
        "@type": "Organization",
        "name": "Anlook",
        "logo": "https://anlook.com/.uploads/anlook_logo.png",
        "url": "https://www.anlook.com/",
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "Customer Support",
          "telephone": "+919958853167",
          "email": "support@anlook.com"
        }
      },
      "potentialAction": {
        "@type": "Action",
        "name": "Talk to an Expert",
        "target": "https://www.anlook.com"
      },
      "image": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp",
      "author": {
        "@type": "Organization",
        "name": "Anlook"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Anlook",
        "logo": {
          "@type": "ImageObject",
          "url": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp"
        }
      }
    })}
  </script>
</Helmet>
{/* //shivani */}
<Helmet>
        <title>
          {pageData ? pageData.basic_settings.tittle : "Loading..."}
        </title>
        <meta
          name="description"
          content={pageData ? pageData.basic_settings.meta_desc : "Loading..."}
        />
        <meta
          name="keywords"
          content={
            pageData
              ? pageData.keyword_settings
                  .map((keyword) => keyword.keyword)
                  .join(", ")
              : "Loading..."
          }
        />
        <link
          rel="canonical"
          href={pageData ? pageData.basic_settings.canonical_tag : "#"}
        />
        <meta
          property="og:title"
          content={
            pageData
              ? pageData.og_settings?.og_title || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:description"
          content={
            pageData
              ? pageData.og_settings?.og_desc || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:image"
          content={pageData ? pageData.og_settings?.og_image || "#" : "#"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
        {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}

      </Helmet>
      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>
      {/* <ChatBot /> */}
      <ExternalChatBot />
      <LandingNav onSignUpClick={onSignUpClick}  onLoginClick={onLoginClick}  country={country} handleCountryChange={handleCountryChange}  />
      {/* First Section */}
      <div>
        <div
          className="row col-12 pt-lg-5  padding-5-rem "
          style={{
            backgroundImage: `url(${herobg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "#0c4d45",
          }}
        >
          <div className="col-12  col-lg-6 d-flex align-items-center">
            <div>
              <div className="text-2-2 pt-3 ">
                <h1>
                  {" "}
                  A Captivating Platform for{" "}
                  <span className="text-green-color">
                    Effortless Marketing!
                  </span>{" "}
                </h1>
              </div>
              <div className="pb-1 text-text-2 pt-4">
                We offer comprehensive solutions that can unlock the full
                potential of your WhatsApp & SMS business
              </div>
              <div className="herosection-features-list">
                <div className="herosection-feature-item d-flex align-items-center py-2 text-text-2">
                  <img
                    src={sn1}
                    alt="feature icon"
                    className="herosection-feature-icon me-2"
                  />
                  <span>
                    Unlimited User Outreach with Broadcasted Promotional Offers
                  </span>
                </div>
                <div className="herosection-feature-item d-flex align-items-center py-2 text-text-2">
                  <img
                    src={sn2}
                    alt="feature icon"
                    className="herosection-feature-icon me-2"
                  />
                  <span>
                    WhatsApp Catalogues & Instant Messaging for Seamless
                    Marketing
                  </span>
                </div>
                <div className="herosection-feature-item d-flex align-items-center py-2 text-text-2">
                  <img
                    src={sn3}
                    alt="feature icon"
                    className="herosection-feature-icon me-2"
                  />
                  <span>
                    24/7 Customer Support with Multi-Agent Live Chat
                    Capabilities
                  </span>
                </div>
              </div>
              <div className="pt-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="  btn  p-2 px-4 me-3 text-white"
                  onClick={onSignUpClick}
                >
                  Get Started
                  <img
                    loading="lazy"
                    src={signup}
                    className="ps-2"
                    alt="signup"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 m-0 p-0  mt-lg-0 p-lg-0 mt-4 d-flex justify-content-end align-items-center">
            <img
              loading="lazy"
              src={group9}
              alt="Anlook enhances customer trust"
              className="img-fluid home-responsive-img "
            />
          </div>
        </div>
      </div>
      {/* Second Section */}
      <SecondSectionFeatures />
      {/* Third Section */}
      <CTA />
      {/* Forth Section */}
      <div className=" padding-5-rem py-5">
        <div className="text-center  text-2-2">
          Harmonize the Experience, Foster Connections,{" "}
        </div>
        <div className="text-center py-2 text-2-2">
          and Optimize with Precision
        </div>
        <div className="row col-12 m-0 p-0 d-flex justify-content-center align-items-center">
          {/* 1 */}
          <div className="col-lg-4 col-12 ">
            <div className="  bg-white d-flex justify-content-center p-4 border-15-only border-green-color-home my-2 ">
              <div className="">
                <div className="d-flex justify-content-center">
                  <div className="bg-green p-2 border-redius-5 my-2 ">
                    <img
                      loading="lazy"
                      src={Chat22}
                      alt="Chat22"
                      height={30}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="text-3-2-gray-tab text-center ">
                  Consolidated Conversational Profiles
                </div>
                <p className="text-center pt-2">
                  Gain a comprehensive overview of customer behavior, accessing
                  identity resolution, engagement data, and marketing journeys
                  seamlessly.
                </p>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="col-lg-4 col-12 ">
            <div className="  bg-white d-flex justify-content-center p-4 border-15-only border-green-color-home my-2">
              <div className="">
                <div className="d-flex justify-content-center">
                  <div className="bg-green p-2 border-redius-5 my-2 ">
                    <img
                      loading="lazy"
                      src={TickShield}
                      alt="TickShield"
                      height={30}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="text-3-2-gray-tab text-center ">
                  Maximize Performance
                </div>
                <p className="text-center pt-2">
                  Gain actionable insights into your engagement, conversions,
                  and campaign effectiveness.
                </p>
              </div>
            </div>
          </div>
          {/* 3 */}
          <div className="col-lg-4 col-12 ">
            <div className="  bg-white d-flex justify-content-center p-4 border-15-only border-green-color-home my-2">
              <div className="">
                <div className="d-flex justify-content-center">
                  <div className="bg-green p-2 border-redius-5 my-2 ">
                    <img
                      loading="lazy"
                      src={Highscalibility}
                      alt="Highscalibility"
                      height={30}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="text-3-2-gray-tab text-center ">
                  High Scalability
                </div>
                <p className="text-center pt-2">
                  Effortlessly send campaigns to numerous customers at once and
                  manage millions of events daily.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Fifth Section */}
      {/* Six Section */}
      <TabSection />
      {/* Seven Section */}
      <div className="bg-pink d-flex justify-content-center  padding-5-rem py-5 ">
        <div>
          <div className="text-center text-dark text-4-2 ">
            Brands using our Digital Customer Service{" "}
          </div>

          <div className="text-center text-dark text-4-2 py-2 pb-5 ">
            Platform have seen
          </div>
          <div className="row col-12 text-normal">
            <div className="col-6 col-lg-3">
              <div className="text-center text-dark text-4-2">40%</div>

              <div className="text-center py-2   text-dark">Cart Recovery</div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center  text-4-2 text-dark">50%</div>

              <div className="text-center py-2   text-dark">
                User Acquisition
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center  text-4-2 text-dark">60%</div>

              <div className="text-center py-2  text-dark ">User Retention</div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center  text-4-2 text-dark">50%</div>

              <div className="text-center py-2  text-dark ">
                Brand Awareness
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Eight Section */}
      <div className=" d-flex bg-white justify-content-center  padding-5-rem py-5 ">
        <div>
          <div className="text-center text-2-2 pb-3">
            More Reasons to Join Us{" "}
          </div>
          <div className="row col-12 d-flex justify-content-center align-items-center m-0 p-0 ">
            <div className="col-12 col-lg-4">
              <div className="  d-flex flex-column justify-content-center p-4 border-15-only border-green-color-home my-2 ">
                <div className=" d-flex align-items-center justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center pb-2">
                      {" "}
                      <img
                        loading="lazy"
                        src={Chat22}
                        alt="Chat22"
                        height={40}
                        className="pe-3"
                      />
                    </div>
                    <p className="text-3-2-gray-tab text-center ">
                      Bulk WhatsApp Messages
                    </p>
                  </div>
                </div>
                <div className=" text-center">
                  Supercharge your brand with Bulk WhatsApp Messages and Emails,
                  Skyrocketing Product Visibility and Driving success through
                  Powerful Marketing Campaigns.
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 my-3 my-lg-0">
              <div className="  d-flex flex-column justify-content-center p-4 border-15-only border-green-color-home my-2 ">
                <div className=" ">
                  <div className="d-flex justify-content-center pb-2">
                    {" "}
                    <img
                      loading="lazy"
                      src={team}
                      alt="team"
                      height={40}
                      className="pe-3"
                    />
                  </div>
                  <p className="text-3-2 text-center ">Team Chat Inbox</p>
                </div>
                <div className=" text-center">
                  Unify your team with a Streamlined Chat Inbox, make Effortless
                  Collaboration and Enable Swift, Seamless Share Vital
                  Information.
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="  d-flex flex-column justify-content-center p-4 border-15-only border-green-color-home my-2 ">
                <div>
                  <div className="d-flex justify-content-center pb-2">
                    {" "}
                    <img
                      loading="lazy"
                      src={tick}
                      alt="tick"
                      height={40}
                      className="pe-3"
                    />
                  </div>
                  <p className="text-3-2 text-center ">
                    Green Tick Verification
                  </p>
                </div>
                <div className=" text-center">
                  Build Trust and Credibility with the coveted WhatsApp Green
                  Verified Tick, a symbol of Authenticity that Elevates your
                  Brand in the eyes of your audience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Nine Section */}
      <BlogSection />
      {/* Ten Section  */}
      <div className="py-5 mb-5 bg-white">
        <FAQSection ref={faqRef} />
      </div>
      {/* Eleven Section Footer */}
      <LandingFooter />
    </div>
  );
};
export default Home;