import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; 
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"; 

const SubscriptionBanner = ({ userID }) => {
  const navigate = useNavigate(); // Initialize navigate
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [freshUser, setFreshUser] = useState(null);
  const [showBanner, setShowBanner] = useState(false); 
  const [showBannerSetup, setShowBannerSetup] = useState(false); 
  const [remainingDays, setRemainingDays] = useState("");
  const [haveCred, setHavecred] = useState(false);
  const verifyCredentials = async () => {
    try {
      const response = await fetch(
        "https://api.anlook.com/api/Whatsapp/getWhatsappCredentials",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userID,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.responseCode === "00") {
        setHavecred(true);
      } else if (responseData.responseCode === "01") {
        setHavecred(false);
        setShowBannerSetup(true);
      } else {
        console.error("Unexpected response code:", responseData);
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };

  const subscriptionDetailsApi = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Example Authorization
        },
      };
      const responseBody = {
        userid: userID,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Entitlement/fetchSubscriptionDetails",
        responseBody,
        config
      );
      if (response.data.responseCode === "00") {
        const details = response.data.data;
        setSubscriptionDetails(details);
        setRemainingDays(parseInt(details.remaining_days));
        // Use useNavigate instead of window.location.href to avoid reloading
        if (parseInt(details.remaining_days) < 0) {
          navigate("/ExpiredPlan"); // Navigate to ExpiredPlan page
        }
        if (parseInt(details.remaining_days) < 7) {
          setShowBanner(true);
        }
      }
      else if (response.data.responseCode === "03") {
        setFreshUser(response.data.responseMessage);
        setShowBanner(true);

        setSubscriptionDetails(response.data.responseMessage);
        navigate("/Profile/Plans"); 
      } 
      else {
        navigate("/ExpiredPlan");
        setShowBanner(true);
        console.error("Failed to fetch subscription details:", response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    subscriptionDetailsApi();
    verifyCredentials();
  }, []);


  return (
    <>
      {/* Modal for subscription expiring soon */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Expiring Soon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your subscription plan is about to expire in {subscriptionDetails?.remaining_days} days. Please renew it soon to avoid interruption.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => {/* Add renewal logic here */}}>
            Renew Now
          </Button>
        </Modal.Footer>
      </Modal>

      {/* One Time Setup Banner */}
      {showBannerSetup && !haveCred && (
        <div
          className="subscription-banner"
          style={{ height: "3rem", backgroundColor: "rgba(249, 255, 249, 1)", position: "relative" }}
        >
          <div className="d-flex justify-content-center align-items-center h-100 " style={{ marginLeft: "150px" }}>
            <span className="text-dark text-footer">
              Please complete your one-time setup to start Anlook Services.
            </span>
            <Link to="/Profile/Setup">
              <Button variant="danger" size="sm" style={{ marginLeft: "30px" }}>
                Complete Setup
              </Button>
            </Link>
          </div>
          {/* Close Button for Setup Banner */}
          <button
            onClick={() => setShowBannerSetup(false)} // Only hide setup banner
            style={{
              position: "absolute",
              top: "5px",
              right: "15px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer"
            }}
          >
            &times;
          </button>
        </div>
      )}

      {/* Subscription Banner */}
      {showBanner && (
        <div className="subscription-banner" style={{ height: "3rem", backgroundColor: "rgba(249, 255, 249, 1)", position: "relative" }}>
          <div className="d-flex justify-content-center align-items-center h-100 " style={{ marginLeft: "150px" }}>
            {
              freshUser 
                ? <span className="text-danger">{freshUser}</span>   // If `freshUser` is truthy, display it
                : remainingDays > 0
                  ? `Your subscription will expire in ${remainingDays} days. Please renew soon!`  // If not a fresh user and subscription is active
                  : 'Your subscription has expired!' 
            }

            <Link to="/Profile/Plans">
              <Button variant="success" size="sm" style={{ marginLeft: "30px" }}>
                Upgrade Plan
              </Button>
            </Link>
          </div>

          {/* Close Button for Subscription Banner */}
          <button
            onClick={() => setShowBanner(false)} // Only hide subscription banner
            style={{
              position: "absolute",
              top: "5px",
              right: "15px",
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer"
            }}
          >
            &times;
          </button>
        </div>
      )}
    </>
  );
};

export default SubscriptionBanner;