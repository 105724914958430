import React from "react";
import PropTypes from "prop-types";
const Pagination = ({
  currentPage,
  totalPages,
  onNextPage,
  onPrevPage,
  onPageClick,
}) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 7; 
    let startPage = Math.max(1, currentPage - 3);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);
    if (endPage - startPage + 1 < maxDisplayedPages) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }
    if (startPage > 1) {
      pageNumbers.push(
        <button key={1} onClick={() => onPageClick(1)} className="page-number">
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(<span key="ellipsis1" className="ellipsis">...</span>);
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => onPageClick(i)}
          className={`page-number ${currentPage === i ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis2" className="ellipsis">...</span>);
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => onPageClick(totalPages)}
          className="page-number"
        >
          {totalPages}
        </button>
      );
    }
    return pageNumbers;
  };
  return (
    <div className="pagination">
      <button
        onClick={onPrevPage}
        disabled={currentPage === 1}
        className="nav-button-next-pre"
      >
        &lt;
      </button>
      {renderPageNumbers()}
      <button
        onClick={onNextPage}
        disabled={currentPage === totalPages}
        className="nav-button-next-pre"
      >
        &gt;
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPrevPage: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
};

export default Pagination;