import React, { useState ,useEffect} from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Card, Tab, Tabs, Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate ,useParams} from "react-router-dom";
import "./AdminDashboard.css"

const TabbledClientDashboard = ({Admin}) => {
  const [Clients, setClients] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [Error, setError] = useState()
  const [Lerror, setLerror] = useState()
  const navigate = useNavigate();
  const [Loading, setLoading] = useState("")
  
  const { clientId } = useParams();
  //Fetching Location
  useEffect(() => {
    // Check if the Geolocation API is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Update latitude and longitude states
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setLerror(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);
  //Fetchin All Clients API call
  const FetchClientCall = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const UserDetail = {
        // userid: User.pkid,
        userid: Admin.pkid,
        latitude: latitude,
        longitude: longitude,
      };

      const response = await axios.post(
        "https://api.anlook.com/api/Admin/getAllClientList",
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setClients(response.data.response);
        console.log(Clients);
        setLoading(false);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("error");
      console.error("Error updating Admin login:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
  FetchClientCall()
  }, [latitude,longitude])
  


  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('campaigns');
  const [filteredClients, setFilteredClients] = useState([]);
  useEffect(() => {
    setFilteredClients(Clients)
    console.log(filteredClients)
    }, [Clients])
    const handleSearch = (term) => {
      setSearchTerm(term);
    
      // If the search term is empty, reset filteredClients to show all clients
      if (!term) {
        setFilteredClients(Clients);
        return;
      }
    
      // Otherwise, filter based on the search term
      const filtered = Clients.filter(client => 
        (client.name && client.name.toLowerCase().includes(term.toLowerCase())) ||
        (client.code && client.code.toLowerCase().includes(term.toLowerCase()))
      );
    
      setFilteredClients(filtered);
    };

    const viewCampaign = (clientId) => {
      // Navigate to the client's Campaign page with the client ID
      navigate(`/admin/Campaigns/${clientId}`);
    };
    const viewTemplates = (clientId) => {
      // Navigate to the client's Campaign page with the client ID
      navigate(`/admin/Templates/${clientId}`);
    };

  const ClientCard = ({ client, type }) => {
    // Provide a default empty array if campaigns or templates are undefined
    const items = type === 'campaigns' ? client.campaigns || [] : client.templates || [];
    
    return (
      <Card className="h-100 border rounded w-100 card1 shadow-none" onClick={()=> viewCampaign(client.us_code)} style={{ backgroundColor: '#fff', cursor:"pointer" }}>
        <Card.Body>
          <div className="border-start border-4 border-success ps-3 mb-3">
            <Card.Title className="text-dark mb-1">{client.us_name}</Card.Title>
            <Card.Subtitle className="text-secondary">Code: {client.us_code}</Card.Subtitle>
          </div>
          <div className="bg-light p-3 rounded">
            <h6 className="text-primary mb-3">
              {type === 'campaigns' ? 'Total Campaigns' : 'Available Templates'}
            </h6>
            <ul className="list-unstyled mb-0">
              {items.map((item, index) => (
                <li 
                  key={index} 
                  className="bg-white rounded p-2 mb-2 d-flex align-items-center"
                  style={{ fontSize: '0.9rem' }}
                >
                  <span 
                    className="rounded-circle bg-success me-2" 
                    style={{ width: '8px', height: '8px', display: 'inline-block' }}
                  ></span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </Card.Body>
      </Card>
    );
  };
  const TemplatesClientCard = ({ client, type }) => {
    // Provide a default empty array if campaigns or templates are undefined
    const items = type === 'campaigns' ? client.campaigns || [] : client.templates || [];
    
    return (
      <Card className="h-100 border rounded w-100 card1 shadow-none" onClick={()=> viewTemplates(client.us_code)} style={{ backgroundColor: '#fff', cursor:"pointer" }}>
        <Card.Body>
          <div className="border-start border-4 border-success ps-3 mb-3">
            <Card.Title className="text-dark mb-1">{client.us_name}</Card.Title>
            <Card.Subtitle className="text-secondary">Code: {client.us_code}</Card.Subtitle>
          </div>
          <div className="bg-light p-3 rounded">
            <h6 className="text-primary mb-3">
              {type === 'campaigns' ? 'Total Campaigns' : 'Available Templates'}
            </h6>
            <ul className="list-unstyled mb-0">
              {items.map((item, index) => (
                <li 
                  key={index} 
                  className="bg-white rounded p-2 mb-2 d-flex align-items-center"
                  style={{ fontSize: '0.9rem' }}
                >
                  <span 
                    className="rounded-circle bg-success me-2" 
                    style={{ width: '8px', height: '8px', display: 'inline-block' }}
                  ></span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div fluid className="p-4 bg-white marginRight">
      <div className="mb-4">
        <Row className="align-items-center g-3">
          <Col>
            <div className="position-relative">
              <FontAwesomeIcon 
                icon={faSearch} 
                className="position-absolute text-secondary"
                style={{ left: '12px', top: '50%', transform: 'translateY(-50%)' }}
              />
              <Form.Control
                type="text"
                placeholder="Search clients by name or code..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="ps-4"
                style={{ paddingLeft: '35px' }}
              />
            </div>
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon={faFilter} />
              Filter
            </Button>
          </Col>
        </Row>
      </div>

      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-4"
      >
        <Tab eventKey="campaigns" title="Campaigns">
          <Row xs={1} md={2} lg={3} className="g-4">
            {filteredClients.map(client => (
              <Col key={client.id}>
                <ClientCard client={client} type="campaigns" />
              </Col>
            ))}
          </Row>
        </Tab>
        <Tab eventKey="templates" title="Templates">
          <Row xs={1} md={2} lg={3} className="g-4">
            {filteredClients.map(client => (
              <Col key={client.id}>
                <TemplatesClientCard client={client} type="templates" />
              </Col>
            ))}
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
};

export default TabbledClientDashboard;