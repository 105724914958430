import React, { useState, useEffect } from "react";
import SearchIcon from "../../images/Search icon.svg";
import image from "../../images/IMG.jpg";
import filter from "../../images/Component 22.svg";
import axios from "axios";
import AddIcon from "../../images/Add.svg";
import { color } from "chart.js/helpers";

// Dummy Data
const dummyData = [
  {
    id: 1,
    title: "Festive_Exclusive_offers",
    category: "Diwali",
    type: "Festive",
    image: image,
    description: "Exclusive Sneak Peek: Get ready for Our Sale!",
  },
  {
    id: 2,
    title: "Happy_Raksha_Bandhan",
    category: "Raksha Bandhan",
    type: "Festive",
    image: image,
    description: "A day of celebration and a bond of a lifetime",
  },
  {
    id: 3,
    title: "Flash_sale_at_outlet",
    category: "Dussehera",
    type: "Discount",
    image: image,
    description: "You're Invited: Exclusive Flash Sale at Our Retail",
  },
  {
    id: 4,
    title: "Happy_Diwali_Offer",
    category: "Diwali",
    type: "Festive",
    image: image,
    description: "We are excited to introduce our exclusive* Diwali",
  },
  {
    id: 5,
    title: "Festive_loan_offers",
    category: "Diwali",
    type: "Promotion",
    image: image,
    description: "Illuminate Diwali with Our Festive Loan Offer",
  },
  {
    id: 6,
    title: "Happy_Independence_day",
    category: "Independence day",
    type: "Festive",
    image: image,
    description: "Its time to get painted with unity and integrity",
  },
  {
    id: 7,
    title: "Happy_Diwali",
    category: "Diwali",
    type: "Festive",
    image: image,
    description: "May Lord Ganesha and Laxmi bring happiness and",
  },
  {
    id: 8,
    title: "Dusshera_offers",
    category: "Dussehera",
    type: "Discount",
    image: image,
    description: "Celebrate this festive season with our new range",
  },
];

// Categories and Subcategories
const categories = {
  Festive: ["Diwali", "Raksha Bandhan", "Independence day"],
  Discount: ["Dussehera"],
  Promotion: [],
};
const TemplateCard = ({ template }) => (
  <div style={cardStyle}>
    <div style={cardContentStyle}>
      <h3 style={cardTitleStyle}>{template.event_type}</h3>
      <div style={dividerStyle}></div>
      <p style={cardTextStyle}>{template.message}</p>
    </div>
    <div style={cardButtonContainerStyle}>
      <button
        style={
          template.status === "ACTIVE" ? cardButtonStyleActive : cardButtonStyle
        }
      >
        Set as Default
      </button>
    </div>
  </div>
);

const TemplateLibrary = ({ userID }) => {
  const [selectedType, setSelectedType] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showFilters, setShowFilters] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const response = await axios.post(
          "https://api.anlook.com/api/Whatsapp/getLibraryTemplates",
          {
            userid: userID,
          },
          config
        );
        if (response.data.responseCode === "00") {
          setTemplates(response.data.response);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemplates();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const filteredTemplates = templates.filter((template) => {
    return (
      (selectedType === "All" || template.type === selectedType) &&
      (selectedCategory === "All" || template.category === selectedCategory)
    );
  });

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setSelectedCategory("All"); // Reset category when type changes
    setIsDropdownOpen(false);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="p-4" style={{ padding: "0px 20px" }}>
      <div
        className="px-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <p className="CreateCampaign">Chatbot Customization</p>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={() => setShowFilters(!showFilters)}
            style={{ background: "none", border: "none" }}
          >
            <img src={filter} alt="" />
          </button>
          <div
            className={`search-bar-container ${isExpanded ? "expanded" : ""}`}
          >
            <div className="search-bar-icon" onClick={toggleExpand}>
              <img src={SearchIcon} alt="Search" />
            </div>
            <input
              className="search-bar-input"
              type="text"
              placeholder="Search"
            />
          </div>

          <button className="blue-outline-button">
            <img src={AddIcon} alt="" /> Custom Message
          </button>
        </div>
      </div>
      <div className="border rounded-3 mx-4 p-5">
        {" "}
        {showFilters && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div style={dropdownStyle}>
              <button
                style={{ ...buttonStyle, ...dropdownButtonStyle }}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedType === "All" ? "Select Type" : selectedType} ⬇
              </button>
              {isDropdownOpen && (
                <div style={dropdownMenuStyle}>
                  {["All", ...Object.keys(categories)].map((type, index) => (
                    <div
                      key={index}
                      style={dropdownItemStyle}
                      onClick={() => handleTypeChange(type)}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {selectedType !== "All" && (
              <div style={{ display: "flex", gap: "10px", marginLeft: "20px" }}>
                {["All", ...categories[selectedType]].map((category, index) => (
                  <button
                    key={index}
                    onClick={() => handleCategoryChange(category)}
                    style={{
                      ...buttonStyle,
                      backgroundColor:
                        selectedCategory === category ? "#007bff" : "#f8f9fa",
                      color: selectedCategory === category ? "#fff" : "#000",
                    }}
                  >
                    {category}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
        <div style={gridStyle}>
          {templates.map((template) => (
            <TemplateCard key={template.id} template={template} />
          ))}
        </div>
      </div>
    </div>
  );
};

const buttonStyle = {
  padding: "8px 16px",
  fontSize: "14px",
  color: "black",
  borderRadius: "8px",
  border: "1px solid #ccc",
  cursor: "pointer",
  backgroundColor: "#f8f9fa",
};

const dropdownStyle = {
  position: "relative",
  display: "inline-block",
};

const dropdownButtonStyle = {
  borderRadius: "8px",
  backgroundColor: "#f8f9fa",
  display: "flex",
  alignItems: "center",
};

const dropdownMenuStyle = {
  position: "absolute",
  top: "100%",
  left: "0",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "8px",
  color: "blue",
  zIndex: 1,
  minWidth: "150px",
};

const dropdownItemStyle = {
  padding: "10px",

  cursor: "pointer",
  color: "#000",
  borderBottom: "1px solid #ccc",
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  gap: "20px",
};

const cardStyle = {
  backgroundColor: "#fff",
  borderRadius: "8px",
  border: "1px solid #ccc",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  overflow: "hidden",
};

const cardContentStyle = {
  padding: "15px",
  flex: 1,
};

const cardTitleStyle = {
  fontSize: "16px",
  fontWeight: "bold",
  marginBottom: "10px",
};

const dividerStyle = {
  height: "1px",
  backgroundColor: "#ccc",
  margin: "10px -15px",
};

const cardTextStyle = {
  fontSize: "14px",
  color: "#555",
  marginTop: "10px",
};

const cardButtonContainerStyle = {
  padding: "10px 15px",
  // borderTop: "1px solid #ccc",
};

const cardButtonStyle = {
  backgroundColor: "rgba(243, 243, 243, 1)",
  color: "rgba(110, 110, 110, 1)",
  border: "1px solid rgba(110, 110, 110, 1)",
  borderRadius: "4px",
  padding: "8px 12px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  width: "100%",
};
const cardButtonStyleActive = {
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  padding: "8px 12px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  width: "100%",
};

export default TemplateLibrary;
