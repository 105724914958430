import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap JS
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Pagination from "./Pagination"; // Ensure the Pagination component is correctly imported

const Logs = ({ userID }) => {
  const [loading, setLoading] = useState(false);
  const [Campaign, setCampaign] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items per page

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getOneWeekBackDate = () => {
    const oneWeekBack = new Date();
    oneWeekBack.setDate(oneWeekBack.getDate() - 7);
    const year = oneWeekBack.getFullYear();
    const month = String(oneWeekBack.getMonth() + 1).padStart(2, '0');
    const day = String(oneWeekBack.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // fetching the campaigns using the Anlook api.
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const getLogs = async () => {
    try {
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        userid: userID,
        fromDate: startDate,
        toDate: endDate
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.anlook.com/api/Whatsapp/getAllWhatsappLogs`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setCampaign(data.response);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    setStartDate(getOneWeekBackDate());
    setEndDate(getCurrentDate());
    getLogs();
  }, []);

  const totalPages = Math.ceil(Campaign.length / itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Sort Campaign array in descending order by date
  const sortedCampaign = [...Campaign].sort((a, b) => new Date(b.indate) - new Date(a.indate));

  const displayedItems = sortedCampaign.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>

      <div>
        <div
          className="p-4 bg-white"
          style={{
            height: "100vh",
            fontFamily: "Nunito,sans-serif",
          }}
        >
          <div className="px-4 justify-content-center">
            <Form className="d-flex justify-content-between pb-4">
              <Form.Label className="CreateCampaign">Message Logs</Form.Label>

              <div className="d-flex">
                <label
                  htmlFor="start-date"
                  className="single-line-css d-flex align-items-center"
                >
                  Start Date
                </label>

                <FormControl
                  type="date"
                  id="start-date"
                  value={startDate}
                  className="bg-light me-5 ms-2"
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Start Date"
                />
                <label
                  htmlFor="end-date"
                  className="single-line-css d-flex align-items-center"
                >
                  End Date
                </label>
                <FormControl
                  type="date"
                  id="end-date"
                  value={endDate}
                  className="bg-light ms-2"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </Form>
            {loading ? (
              <div className="m-5 p-5 d-flex justify-content-center">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                <table className="table table-hover">
                  <thead style={{ borderRadius: "10px" }}>
                    <tr
                      className="text-center greenColour py-4"
                      style={{
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "7px 0px 0px 7px",
                        }}
                      >
                        S.No.
                      </th>
                     
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Type
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Name
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Mobile Number
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Time
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "0px 7px 7px 0px",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {displayedItems.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid #DDD",
                          padding: "5px 0px",
                        }}
                      >
                        <td className="p-3 text-center">{(currentPage - 1) * itemsPerPage + index + 1}</td>

                        <td className="p-3 text-center">{item.category}</td>
                        <td className="p-3 text-center">{item.contact_name}</td>
                          <td className="p-3 text-center">{item.contact}</td>
                        <td className="p-3 text-center">{item.indate}</td>

                        <td className="p-3 text-center">
                          {item.status === "read" ? (
                            <span
                              className="badge text-center w-100 p-2"
                              style={{
                                color: " #007E59",
                                border: "solid 1px #007E59",
                                borderRadius: "5px",
                              }}
                            >
                              Read
                            </span>
                          ) : (
                            <span
                              className="badge text-center w-100 p-2"
                              style={{
                                color: "#000",
                                border: "solid 1px #000",
                                borderRadius: "5px",
                              }}
                            >
                              {item.status}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
<div className="pb-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePrevPage}
                  onPageClick={handlePageClick}
                />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Logs;
