import React, { useEffect } from 'react';
const ExternalChatBot = () => {
  
  useEffect(() => {
    if (!window.Tawk_API) {
      var Tawk_API = Tawk_API || {};
      var Tawk_LoadStart = new Date();
      const script = document.createElement("script");
      script.src = 'https://embed.tawk.to/672dfde04304e3196adf4c7d/1ic5qfe6s';
      script.async = true;
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
  return null; 
};
export default ExternalChatBot;
