import { ToastContainer } from "react-toastify";
import ss1 from "../../../images/LandingPageImage/web_campaign_broadcast_1x.webp";
import ss2 from "../../../images/LandingPageImage/web_chat_bot_1x.webp";
import ss3 from "../../../images/LandingPageImage/storefront.webp";
import ss4 from "../../../images/LandingPageImage/order_feature.webp";
import signup from "../../../images/signup.svg";

const SecondSectionFeatures = () => {
  return (
    <>
      <ToastContainer />
      <div className="d-flex flex-column justify-content-center bg-green-removed padding-5-rem">
        <div className="text-center pt-4 pt-lg-5 text-2-2">
          <h1>Features</h1>
        </div>
        <div className="text-center text-text-2 pt-2 pt-lg-2">
          WhatsApp Campaigns and Intuitive Chatbots help enhance your Engagements and Target Acquisitions.
        </div>
        <div className="text-center py-2 text-text-2 mb-5">
          Efficiently Manage Inquiries and Updates through a Unified Inbox and Seamless Integrations.
        </div>

        {/* First Feature Section */}
        <div className="row col-12 py-lg-2 py-2">
          <div className="col-12 col-lg-6 order-2 order-lg-1 d-flex align-items-center">
            <div>
              <div className="text-3-2-large pt-2 pt-lg-0">
                Automation & Campaign Management
              </div>
              <ul className="feature-list mt-3">
                <li>Automate WhatsApp campaigns to manage communications efficiently.</li>
                <li>Schedule personalized messages to reach your audience at the right time.</li>
                <li>Increase engagement by delivering relevant messages without manual intervention.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center">
            <img
              loading="lazy"
              src={ss1}
              alt="Automation features: efficiency, reliability, and innovation"
              className="feature-responsive-image"
            />
          </div>
        </div>

        {/* Second Feature Section */}
        <div className="row col-12 pt-5">
          <div className="col-12 col-lg-6 order-1 order-lg-1 d-flex justify-content-center">
            <img
              loading="lazy"
              src={ss2}
              alt="Template creation feature"
              className="feature-responsive-image"
            />
          </div>
          <div className="col-12 col-lg-6 order-2 order-lg-2 d-flex align-items-center">
            <div className="ps-lg-5 ps-0">
              <div className="text-3-2-large pt-2 pt-lg-0">
                Template Creation
              </div>
              <ul className="feature-list mt-3">
                <li>Design professional message templates tailored to customer needs.</li>
                <li>Customizable templates for communication that aligns with your brand.</li>
                <li>Drive higher open and response rates by delivering targeted messages.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Third Feature Section */}
        <div className="row col-12 pt-5">
          <div className="col-12 col-lg-6 order-2 order-lg-1 d-flex align-items-center">
            <div>
              <div className="text-3-2-large">
                WhatsApp Storefront
              </div>
              <ul className="feature-list mt-3">
                <li>Set up a digital storefront on WhatsApp to showcase products or services.</li>
                <li>Allow customers to browse, inquire, and shop directly through WhatsApp.</li>
                <li>Use built-in order management to turn inquiries into sales.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center pb-lg-0 pb-2">
            <img
              loading="lazy"
              src={ss3}
              alt="WhatsApp Storefront feature"
              className="feature-responsive-image"
            />
          </div>
        </div>

        {/* Fourth Feature Section */}
        <div className="row col-12 pt-5 pb-0 pb-lg-5">
          <div className="col-12 col-lg-6 order-1 order-lg-1 d-flex justify-content-center">
            <img
              loading="lazy"
              src={ss4}
              alt="Order management feature"
              className="feature-responsive-image"
            />
          </div>
          <div className="col-12 col-lg-6 order-2 order-lg-2 d-flex align-items-center">
            <div className="ps-lg-5 ps-0">
              <div className="text-3-2-large">
                Order Management
              </div>
              <ul className="feature-list mt-3">
                <li>Manage customer orders from placement to fulfillment within WhatsApp.</li>
                <li>Automate order confirmations, shipping updates, and customer service.</li>
                <li>Track sales and respond to inquiries instantly, keeping customers informed.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSectionFeatures;
