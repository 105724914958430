import React, { useEffect } from "react";
import  { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import frame1 from "../../images/Frame1.svg";
import frame2 from "../../images/Frame2.svg";
import frame3 from "../../images/Frame3.svg";
import youtube from "../../images/youtube.svg";
import linkedin from "../../images/linkedin.svg";
import Anlook from "../../images/Anlook.svg";

const socialLinks = {
  youtube: "https://www.youtube.com/@Anlookofficial",
  insta: "https://www.instagram.com/anlookofficial/",
  facebook: "https://www.facebook.com/anlookfb/",
  linkedin: "https://www.linkedin.com/company/anlook-official/"
};


const LandingFooter =  ({ scrollToFAQ }) => {
  const { pathname } = useLocation();
  const faqRef = useRef(null); // Create a ref for the FAQ section


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="  bg-footer  padding-5-rem">
          <div className="row col-12 pt-4">
            <div className="col-lg-5 col-12">
              <div className="p-1 p-lg-4">
                <img src={Anlook} width="150" alt="" />
                <div className="py-4">
                  At Anlook, our goal is to empower brands by offering personalized conversation services that cater to their end consumers. We are dedicated to providing seamless experiences that yield optimal results, driven by our commitment to your growth. Our ethos revolves around building stronger customer relationships through meaningful conversations.
                </div>
                {/* <div className="pt-3">
                  <button
                    style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className=" btn p-2 px-lg-5 px-2 me-5 text-white"
                    onClick={() => {
                      window.open(
                        "https://wa.me/message/HI7RNT6ZMMT4P1"
                      );
    
                    }}
                  >
                    Get in touch
                  </button>
                </div> */}
              </div>
            </div>
            <div className="col-6 col-lg-2">
              <div className="p-lg-4  p-1">
                <div className="text-3-2 pb-3">Company</div>
                <div className="py-2">
                  <Link to="/AboutUs" className="no-decoration">About Us</Link>
                </div>
                <div className="py-2">
                  <Link to="/PrivacyPolicy" className="no-decoration">Privacy Policy</Link>
                </div>
                <div className="py-2">
                  <Link to="/pricing" className="no-decoration">Pricing</Link>
                </div>
                <div className="py-2">
                  <Link to="/T&C" className="no-decoration">Terms & Condition</Link>
                </div>
                <div className="py-2">
                  <Link to="/Cancellation" className="no-decoration">Cancellation Policy</Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-2">
              <div className="p-lg-4  p-1">
                <div className="text-3-2 pb-3">Resources</div>
                <div className="py-2">
                  <Link to="/blogs" className="no-decoration">Blogs</Link>
                </div>
                {/* <div className="py-2">
                <Link to="/"  onClick={scrollToFAQ} className="no-decoration">FAQ</Link>
                </div> */}
              </div>
            </div>

            
            <div className="row col-12 col-lg-3">
              <div className="p-lg-4  padding-5-rem">
                <div className="text-3-2 pb-3">Follow Us</div>
                <div className="d-flex">
                  <div className="pe-2">
                    <a href={socialLinks.insta} target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={frame1} alt="Instagram" />
                    </a>
                  </div>
                  <div className="px-2">
                    <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={frame2} alt="Facebook" />
                    </a>
                  </div>
                  <div className="px-2">
                    <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={youtube} alt="YouTube" />
                    </a>
                  </div>
                  <div className="ps-2">
                    <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={linkedin} alt="LinkedIn" />
                    </a>
                  </div>
                </div>
              </div>
            </div>



          </div>

          <hr style={{ border: 'none', borderTop: '1px solid #000', margin: '20px 0', width: '100%' }} />


          <div className="d-flex justify-content-center pb-3">
            Anlook © 2024. All rights reserved.
          </div>
      
      </div>
    </>
  );
};

export default LandingFooter;