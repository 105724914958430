import React, { useEffect, useState } from 'react';

const WhatsAppSignup = ({UserID}) => {
  const [isFBReady, setIsFBReady] = useState(false);

  // Initialize the Facebook SDK
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '801281555533147',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0',
      });
      setIsFBReady(true); // Indicate that FB is ready to use
    };

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Facebook login callback
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      
    }
    document.getElementById('sdk-response').textContent = JSON.stringify(response, null, 2);
  };

  // Function to launch WhatsApp Signup
  const launchWhatsAppSignup = () => {
    if (isFBReady && window.FB) {
      window.FB.login(fbLoginCallback, {
        config_id: '565106129591059', // configuration ID goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          setup: {},
          featureType: '',
          sessionInfoVersion: '2',
        },
      });
    } else {
      console.error('Facebook SDK is not loaded yet.');
    }
  };

  // Message event listener
  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.origin !== 'https://www.facebook.com' &&
        event.origin !== 'https://web.facebook.com'
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log('Phone number ID ', phone_number_id, ' WhatsApp business account ID ', waba_id);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn('Cancel at ', current_step);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error('error ', error_message);
          }
        }
        
        document.getElementById('session-info-response').textContent = JSON.stringify(data, null, 2);
      } catch {
        console.log('Non JSON Responses', event.data);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      <div id="fb-root"></div>
      <button
        onClick={launchWhatsAppSignup}
        style={{
          backgroundColor: '#1877f2',
          border: 0,
          borderRadius: '4px',
          color: '#fff',
          cursor: 'pointer',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: '16px',
          fontWeight: 'bold',
          height: '40px',
          padding: '0 24px',
        }}
      >
        Login with Facebook
      </button>
      <p>Session info response:</p>
      <pre id="session-info-response"></pre>
      <br />
      <p>SDK response:</p>
      <pre id="sdk-response"></pre>
    </div>
  );
};

export default WhatsAppSignup;
