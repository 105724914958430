import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./AdminDashboard.css";
import NoIndex from "../NoIndex";

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const FetchMessages = async (userId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const UserDetail = {
        mobile: "9168805979",
        message: "",
      };

      const response = await axios.post(
        "https://api.anlook.com/api/User/getSupportChatsAdmin",
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        setMessages(response.data.response);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("error");
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    FetchMessages();
  }, []);

  const SendMessageAPI = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const UserDetail = {
        mobile: "9168805979",
        message: inputMessage,
      };

      const response = await axios.post(
        "https://api.anlook.com/api/User/sendSupportChatAdmin",
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: "sent", message: inputMessage },
        ]);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("error");
      console.error("Error sending message:", error);
    } finally {
      FetchMessages();
    }
  };

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      setMessages([
        ...messages,
        { type: "sent", message: inputMessage.trim() },
      ]);
      setInputMessage("");
      SendMessageAPI(); // Call API to send message
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="chat-cc-container ">
       <NoIndex/>
      <div className="chat-cc-header">Chat</div>
      <div className="chat-cc-messages">
        {messages.map((message, index) => (
          <div key={index} className={`chat-cc-message ${message.type}`}>
            <div className="chat-cc-message-content">{message.message}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-cc-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
      <style jsx>{`
        .chat-cc-container {
          max-width: 80%;
          max-height: 100%;
          margin: 20px auto;
          background: white;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          margin-left: 19rem;

          flex-direction: column;9
          height: 600px;
          overflow: hidden;
        }

        .chat-cc-header {
          background-color: rgb(25, 135, 84);
          color: white;
          padding: 15px;
          text-align: center;
          font-size: 1.2em;
          font-weight: bold;
        }

        .chat-cc-messages {
          flex-grow: 1;
          padding: 20px;
          overflow-y: auto;
          background-color: #f0f0f0;
        }

        .chat-cc-message {
          margin-bottom: 15px;
          display: flex;
        }

        .chat-cc-message.RECEIVED {
          justify-content: flex-start;
        }

        .chat-cc-message.SENT {
          justify-content: flex-end;
        }

        .chat-cc-message-content {
          padding: 10px 15px;
          border-radius: 20px;
          max-width: 90%;
          word-wrap: break-word;
        }

        .chat-cc-message.RECEIVED .chat-cc-message-content {
          background-color: rgb(25, 135, 84, 0.1);
          color: black;
        }

        .chat-cc-message.SENT .chat-cc-message-content {
          background-color: rgb(25, 135, 84);
          color: white;
        }

        .chat-cc-input {
          display: flex;
          padding: 10px;
          background-color: white;
          border-top: 1px solid #eee;
        }

        .chat-cc-input input {
          flex-grow: 1;
          padding: 10px 15px;
          border: 1px solid #ddd;
          border-radius: 20px;
          margin-right: 10px;
        }

        .chat-cc-input button {
          background-color: #0d6ffc;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          cursor: pointer;
        }

        @media (max-width: 600px) {
          .chat-cc-container {
            margin: 0;
            margin-left: 1rem;
            border-radius: 0;
            height: 100vh;
          }

          .chat-cc-message-content {
            max-width: 95%;
          }
        }
      `}</style>
    </div>
  );
};

export default ChatComponent;
