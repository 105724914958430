import React from "react";
import { useEffect, useRef } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip, // Aliasing Recharts Tooltip
  ResponsiveContainer,
} from "recharts";
import { Line, Bar, Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip as ChartJSTooltip,
  Legend,
} from "chart.js"; // Aliasing Chart.js Tooltip
import "chartjs-adapter-date-fns";

// Registering ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  ChartJSTooltip,
  Legend
);
const chatdata2 = [
  { name: "Jan", uv: 1000 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
];

const chatdata = [
  { name: "Day 1", chats: 40 },
  { name: "Day 2", chats: 60 },
  { name: "Day 3", chats: 100 },
  { name: "Day 4", chats: 70 },
  { name: "Day 5", chats: 120 },
  { name: "Day 6", chats: 40 },
  { name: "Day 7", chats: 150 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].payload.name} : ${payload[0].value} Chats`}</p>
      </div>
    );
  }
  return null;
};

const Analytics = () => {
  // Dummy data arrays
  const campaignData = {
    labels: [
      "2024-08-01",
      "2024-08-08",
      "2024-08-15",
      "2024-08-22",
      "2024-08-29",
      "2024-09-05",
      "2024-09-12",
    ],
    datasets: [
      {
        type: "line",
        label: "Campaign 4 Engagements",
        data: [300, 330, 310, 250, 150, 510, 580, 630],
        borderColor: "#007bff",
        backgroundColor: "rgba(0, 123, 255, 0.2)",
        tension: 0.4,
        pointStyle: "circle",
        fill: true,
        hoverBackgroundColor: "#0056b3",
      },
    ],
  };
  const orderData = {
    labels: ["Burgers", "Pizzas", "Sushi", "Pasta", "Salads"],
    datasets: [
      {
        label: "Food Orders",
        data: [350, 450, 200, 300, 150],
        backgroundColor: [
          "#FF6A6A", // Soft red
          "#4D9EF6", // Soft blue
          "#FFD966", // Soft yellow
          "#7FFFD4", // Soft teal
          "#CDAAFA", // Soft purple
        ],
        borderColor: "#FFFFFF", // White border for clean separation
        borderWidth: 2,
        hoverBorderColor: "#000000", // Black border on hover for emphasis
        hoverOffset: 15, // Slight increase in hover effect
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "65%", // Adjust the cutout for a more modern look
    plugins: {
      legend: {
        position: "bottom", // Place the legend at the bottom
        labels: {
          padding: 20, // Increase padding for spacing
          font: {
            size: 16, // Larger font for better readability
            weight: "500",
            family: "Arial, sans-serif", // Use a professional font
            color: "#333", // Dark grey color for text
          },
          usePointStyle: true, // Use points instead of lines in the legend
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Dark background for tooltips
        bodyFont: {
          size: 14,
          family: "Arial, sans-serif",
        },
        cornerRadius: 10, // Rounded corners for tooltips
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw} orders`;
          },
        },
      },
    },
    animation: {
      duration: 1000, // Smooth animation duration
      easing: "easeOutCubic", // Easing effect for the animation
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
  };
  const templateData = {
    labels: ["Template 1", "Template 2", "Template 3", "Template 4"],
    datasets: [
      {
        label: "Templates Used",
        data: [120, 230, 180, 250],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 99, 132, 0.6)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
      },
    ],
  };

  const templatechartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          font: {
            size: 14,
            family: "Arial, sans-serif",
          },
          color: "#4B5563",
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleFont: {
          size: 16,
          family: "Arial, sans-serif",
        },
        bodyFont: {
          size: 14,
          family: "Arial, sans-serif",
        },
        padding: 10,
        caretPadding: 5,
        borderColor: "#4B5563",
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: "Arial, sans-serif",
          },
          color: "#6B7280",
        },
      },
      y: {
        grid: {
          color: "rgba(219, 234, 254, 0.5)",
          borderDash: [5, 5],
        },
        ticks: {
          font: {
            size: 12,
            family: "Arial, sans-serif",
          },
          color: "#6B7280",
          beginAtZero: true,
        },
      },
    },
  };

  const chatData = {
    labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"],
    datasets: [
      {
        label: "Total Chats",
        data: [40, 60, 80, 90, 120, 130, 150],
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        tension: 0.4,
        fill: true, // This will create the area under the line
      },
    ],
  };
  const leadData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Leads Generated",
        data: [50, 80, 100, 150, 180, 220],
        borderColor: "rgba(255, 159, 64, 1)",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        tension: 0.4,
      },
    ],
  };

  return (
    <div
      className="p-4 bg-white analytics-container"
      style={{
        fontFamily: "Nunito,sans-serif",
      }}
    >
      <div className="px-4 justify-content-center">
        <div className="CreateCampaign pb-4">Analytics</div>

        <div className="row col-12 m-0 p-0 jystify-content-between"></div>

        <div className="row col-12 m-0 p-0 d-flex justify-content-between">
          <div className="col-md-3 col-sm-6 mb-4 p-0">
            <div className="border-gray p-4 border-primary chart-section rounded shadow-sm d-flex flex-column justify-content-center align-items-center text-primary">
              <div className="text-4 font-weight-bold text-primary mb-2">4</div>
              <div className="text-muted">Active Campaign</div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4 p-0">
            <div className="border-gray p-4 border-primary chart-section rounded shadow-sm d-flex flex-column justify-content-center align-items-center text-primary">
              <div className="text-4 font-weight-bold text-primary mb-2">
                2500
              </div>
              <div className="text-muted">Audience</div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4 p-0">
            <div className="border-gray p-4 border-primary chart-section rounded shadow-sm d-flex flex-column justify-content-center align-items-center text-primary">
              <div className="text-4 font-weight-bold text-primary mb-2">
                700
              </div>
              <div className="text-muted">Lead Generated</div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-4 p-0">
            <div className="border-gray p-4 border-primary chart-section rounded shadow-sm d-flex flex-column justify-content-center align-items-center text-primary">
              <div className="text-4 font-weight-bold text-primary mb-2">
                250
              </div>
              <div className="text-muted">Total Orders</div>
            </div>
          </div>

          <div className="col-12 m-0 p-0">
            <div className="chart-section p-4">
              <h2 className="pb-1">Chat Analytics</h2>
              <div className="chart-container">
                <div className=" w-100  border-15-only px-1 ">
                  <ResponsiveContainer height={250}>
                    <AreaChart
                      data={chatdata2}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv4"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="rgba(151, 143, 237, 1)"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="rgba(254, 253, 255, 1)"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        tick={{ fill: "rgba(100, 100, 200, 1)", fontSize: 12 }}
                      />
                      <YAxis
                        tick={{ fill: "rgba(100, 100, 200, 1)", fontSize: 12 }}
                      />
                      <RechartsTooltip
                        content={<CustomTooltip />}
                        cursor={{ strokeDasharray: "3 3" }}
                      />
                      <Area
                        type="monotone"
                        dataKey="uv"
                        stroke="rgba(151, 143, 237, 1)"
                        fill="url(#colorUv4)"
                        strokeWidth={2}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-4 m-0 p-0">
            <div className="chart-section p-4">
              <h2>Food Order Management</h2>
              <div className="chart-container ">
                <Doughnut data={orderData} options={chartOptions} />
              </div>
            </div>
          </div> */}
          {/* <div className="col-4 m-0 p-0">
            <div className="chart-section p-4">
              <h2>Template Usage</h2>
              <div className="chart-container">
                <Bar data={templateData} options={templatechartOptions} />
              </div>
            </div>
          </div> */}
          <div className="col-12 m-0 p-0">
            <div className="chart-section p-4">
              <h2>Campaign Engagements</h2>
              <div className="chart-container-top">
                <Line
                  data={campaignData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "top",
                        labels: {
                          font: {
                            size: 14,
                            family: "Arial, sans-serif",
                            weight: "bold",
                          },
                          color: "#555",
                          padding: 20,
                        },
                      },
                      tooltip: {
                        mode: "index",
                        intersect: false,
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        titleFont: {
                          size: 14,
                          weight: "bold",
                          family: "Arial, sans-serif",
                        },
                        bodyFont: {
                          size: 12,
                          family: "Arial, sans-serif",
                        },
                        borderColor: "#ccc",
                        borderWidth: 1,
                      },
                    },
                    scales: {
                      x: {
                        type: "time",
                        time: {
                          unit: "week",
                          tooltipFormat: "MMM dd",
                          displayFormats: {
                            week: "MMM dd", // Display only month and day
                          },
                        },
                        title: {
                          display: true,
                          text: "Date (Weekly)",
                          font: {
                            size: 16,
                            family: "Arial, sans-serif",
                            weight: "bold",
                          },
                          color: "#333",
                        },
                        ticks: {
                          color: "#555",
                          font: {
                            size: 14,
                            family: "Arial, sans-serif",
                            weight: "bold",
                          },
                        },
                        grid: {
                          color: "rgba(0, 0, 0, 0.05)", // Light gray for less intrusive grid lines
                          borderDash: [5, 5], // Slightly more prominent dash for grid lines
                          lineWidth: 1,
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "Audience",
                          font: {
                            size: 16,
                            family: "Arial, sans-serif",
                            weight: "bold",
                          },
                          color: "#333",
                        },
                        ticks: {
                          beginAtZero: true,
                          stepSize: 100,
                          color: "#555",
                          font: {
                            size: 14,
                            family: "Arial, sans-serif",
                            weight: "bold",
                          },
                        },
                        grid: {
                          color: "rgba(0, 0, 0, 0.05)", // Light gray for less intrusive grid lines
                          borderDash: [5, 5], // Slightly more prominent dash for grid lines
                          lineWidth: 1,
                        },
                      },
                    },
                    elements: {
                      line: {
                        borderWidth: 3,
                        borderColor: "rgba(0, 123, 255, 1)",
                        backgroundColor: "rgba(0, 123, 255, 0.2)",
                        tension: 0.4,
                        fill: true, // Fill below the line with color
                        shadowBlur: 10,
                        shadowColor: "rgba(0, 0, 0, 0.2)",
                      },
                      point: {
                        radius: 6,
                        backgroundColor: "#007bff",
                        borderColor: "#fff",
                        borderWidth: 2,
                        hoverRadius: 8,
                        hoverBorderWidth: 3,
                        hoverBackgroundColor: "#0056b3",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
