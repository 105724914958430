import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import createCampaignImage from "../../../images/LandingPageImage/web_create_campaign_1x.webp";
import createTemplateImage from "../../../images/LandingPageImage/web_create_templates_1x.webp";
import chatSectionImage from "../../../images/LandingPageImage/web_chats_1x.webp";
import dashboardImage from "../../../images/LandingPageImage/web_dashboard_1x.webp";
import templateStatusImage from "../../../images/LandingPageImage/web_template_status_1x.webp";

const tabs = [
  {
    id: 0,
    label: "Dashboard",
    content:
      "An overview of your WhatsApp Bulk Messaging Activities, including Campaign Performance, Subscriber Statistics, Account Settings & more.",
    img: dashboardImage,
  },
  {
    id: 1,
    label: "Create Template",
    content:
      "Design Custom Message Templates tailored for your WhatsApp Bulk Campaigns. This feature allows you to Craft your Personalized and Engaging Messages efficiently.",
    img: createTemplateImage,
  },
  {
    id: 2,
    label: "Template Status",
    content:
      "Check the status of your existing message templates, including their approval status, usage metrics, and any necessary updates or modifications.",
    img: templateStatusImage,
  },
  {
    id: 3,
    label: "Chat Section",
    content:
      "Interactive Chat Section to engage with Subscribers, Address Inquiries, and Manage Conversations in real-time. Enhance the Customer Support.",
    img: chatSectionImage,
  },
  {
    id: 4,
    label: "Run Campaign",
    content:
      "Initiate WhatsApp Bulk Messaging Campaigns, specify Target Audiences, Schedule Delivery Time, and selecting appropriate message templates to maximize engagement and outreach.",
    img: createCampaignImage,
  },
];

const TabSection = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setLoading(true); // Reset loading state when switching tabs
  };

  useEffect(() => {
    const img = new Image();
    img.src = tabs[activeTab].img;
    img.onload = () => setLoading(false); // Set loading to false once preloaded
  }, [activeTab]);

  return (
    <>
      <ToastContainer />

        <div className="padding-5-rem d-flex flex-column justify-content-center bg-light-blue py-5">
            <div className="text-center text-2-2">
              Rapid Integration with Your Existing Apps and Platforms
            </div>

            <div className="">
  <div className="tab-buttons-main py-3 ">
    {tabs.map((tab) => (
      <button
        key={tab.id}
        onClick={() => handleTabClick(tab.id)}
        className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
      >
        {tab.label}
      </button>
    ))}
  </div>
</div>

              <div className="tab-content">
                <div className="text-3-2-gray-tab pb-4 text-center-start padding-tab" >
                  {tabs[activeTab].content}
                </div>

                <div className="tab-image-container d-flex justify-content-center ">
                  {loading && (
                    <div
                    className="tab-loading-overlay position-absolute shimmer"
                    style={{
                      backgroundColor: "#ddd",
                      top: 0,
                      left: 50,
                      width: "80%",
                      height: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "20px",
                    }}
                  >
                    <div className="text-center text-3-2">Loading...</div>
                  </div>
                  )}

<img
  loading="lazy"
  src={tabs[activeTab].img}
  alt={tabs[activeTab].label}
  style={{
    height: "auto",              // Maintain aspect ratio
    maxWidth: "100%",            // Ensure the image doesn't exceed the container width
    objectFit: "contain",        // Ensures the full image is shown without cropping
    opacity: loading ? 0 : 1,    // Fade-in effect
    transition: "opacity 0.5s ease-in", // Smooth fade-in transition
    borderRadius:"15px"
  }}
  onLoad={() => setLoading(false)} // Set loading to false once the image is loaded
  onError={() => setLoading(false)} // Handle errors, hide "Loading..." in case of an error
  className="tab-responsive-image"     // Add a class for media query styling
/>
                </div>
              </div>
          
        </div>
    </>
  );
};

export default TabSection;
