import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import { Modal, Button, ListGroup, Spinner, Alert } from 'react-bootstrap';
import dummyimg from "../../images/dummy-post-image.svg"
// Categories and Subcategories
const categories = {
  Festive: ["Diwali", "Raksha Bandhan", "Independence day"],
  Discount: ["Dussehera"],
  Promotion: [],
};
const FaceBookFeed = () => {
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userID, setUserID] = useState("1");
  const [isExpanded, setIsExpanded] = useState(false);
  const BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const handlePreview = (template) => {
    setSelectedTemplate(template); 
    setShowModal(true);           
  };
  const handleClose = () => setShowModal(false); 
  const getCampaign = async () => {
    try {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      const raw = JSON.stringify({
        userid: userID,
        campaignName: "string",
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `https://api.anlook.com/api/Campaign/getCampaigns`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode === "00") {
        setCampaign(data?.data);
      } else {
        console.log(data?.responseMessage || "Failed to fetch campaigns");
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCampaign();
  }, []);
  const filteredTemplates = campaign.filter((template) => {
    return (
      (selectedType === "All" || template.type === selectedType) &&
      (selectedCategory === "All" || template.category === selectedCategory)
    );
  });
  const handleTypeChange = (type) => {
    setSelectedType(type);
    setSelectedCategory("All"); 
        setIsDropdownOpen(false);
  };
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded); 
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [userInfo, setUserInfo] = useState(null); // Store user info from session
  useEffect(() => {
    // Check if the user is already logged in by checking sessionStorage
    const userSession = sessionStorage.getItem("userInfo");
    if (userSession) {
      setIsLoggedIn(true);
      setUserInfo(JSON.parse(userSession)); // Retrieve user info from session storage
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  // Redirect to Facebook OAuth URL
  const handleFacebookLogin = () => {
    window.location.href = "https://goseo.in/facebook/facebook_oauth.php"; // Redirect to Facebook login page
  };
  // Handle Logout functionality
  const handleLogout = () => {
    sessionStorage.removeItem("userInfo");
    setIsLoggedIn(false);
    setUserInfo(null);
  };
  const handleShare = (template) => {
    // Logic to share the template
    console.log("Share clicked for", template.campaign_name);
  };
  const [userData, setUserData] = useState(null);
  const [url, setUrl] = useState("");
 // Function to fetch user data using API
const fetchData = async () => {
    try {
      const requestBody = { userid: url }; // Using 'url' as 'userid'
      console.log('Request Body:', requestBody);
      const response = await axios.post(`https://api.goseo.in/api/Facebook/GetFacebookUserDetails`, requestBody);
      console.log('API Response:', response.data); // Debugging: Log response data
      if (response.data) {
        const { name, id: userId, email } = response.data;
        const userDetails = { name, id: userId, email };
        console.log('Setting userData:', userDetails); // Debugging: Log user data
        setIsLoggedIn(true);
        setUserData(userDetails);
        sessionStorage.setItem('userdata', JSON.stringify(userDetails));
        console.log('User data stored in sessionStorage'); // Debugging: Log storage success
      } else {
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error.response ? error.response.data : error.message);
    }
  };
  useEffect(() => {
    const getUserIdFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('userid');
      setUrl(userId); 
    };
    getUserIdFromUrl();
  }, []);
  useEffect(() => {
    if (url) { 
      fetchData();
    }
  }, [url]);
  const [isSessionStorageUsed, setIsSessionStorageUsed] = useState(false); 
  useEffect(() => {
    // Check if 'userdata' exists in sessionStorage
    const storedUserData = sessionStorage.getItem('userdata');
    if (storedUserData) {
      console.log('Session storage contains:', JSON.parse(storedUserData));
      setUserData(JSON.parse(storedUserData));
      setIsSessionStorageUsed(true);  
      setIsLoggedIn(true);
    } else {
      console.log('No userdata found in session storage.');
      setIsSessionStorageUsed(false); 
    }
  }, []);

  // ####################################

    // Initial states
    const [showPageSelection, setShowPageSelection] = useState(false);
    const [pages, setPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState(null);
    const [isPostDisabled, setIsPostDisabled] = useState(true);
    const [loadingPages, setLoadingPages] = useState(false); // Loader state
    const [errorMessage, setErrorMessage] = useState(''); // Error handling for fetching pages
    const [isPosting, setIsPosting] = useState(false);
  const [error, setError] = useState(null);

    // Reset states on modal close
    const handleModalClose = () => {
      setShowPageSelection(false);
      setPages([]);
      setSelectedPage(null);
      setIsPostDisabled(true);
      setLoadingPages(false);
      setErrorMessage('');
      handleClose();
    };
  
    // Fetch pages when "Select Page" button is clicked
    const handleSelectPageClick = () => {
      setLoadingPages(true); // Start loading
      axios
        .post('https://api.goseo.in/api/Facebook/GetFacebookPages', { userid: 'a1001' })
        .then((response) => {
          const fetchedPages = response.data.data;
          setPages(fetchedPages);
          setShowPageSelection(true); // Show page selection view
          setLoadingPages(false); // Stop loading
        })
        .catch((error) => {
          console.error('Error fetching pages:', error);
          setErrorMessage('Failed to fetch pages. Please try again.');
          setLoadingPages(false); // Stop loading
        });
    };
  
    // Handle selecting a page
    const handlePageSelect = (page) => {
      setSelectedPage(page);
      setIsPostDisabled(false); // Enable "Post Now" when a page is selected
    };
  
  
    const handlePostNow = async () => {
      // Ensure that the selected page and required template data exist
      if (!selectedPage) {
        alert('Please select a page.');
        return;
      }
  
      // Set loading state
      setIsPosting(true);
      setError(null);
  
      // Construct the payload
      const payload = {
        pageid: selectedPage.id, // Page ID from the selected page
        accessToken: selectedPage.access_token, // Page access token from the selected page
        message: selectedTemplate.bodyParams ? selectedTemplate.bodyParams[0] : 'Default message',
        link: '', 
        // link: selectedTemplate.headerParams ? selectedTemplate.headerParams[0] : '',
        // featured_image: "", 
        featured_image: selectedTemplate.headerParams ? selectedTemplate.headerParams[0] : '', 
        tittle: selectedTemplate.campaign_name || 'Default Title',
        description: 'Your custom description here', // Replace with actual description if required
      };
  
      try {
        // Call the API
        const response = await axios.post('https://api.goseo.in/api/Facebook/PostFacebookFeed', payload);
  
        if (response.status === 200) {
        toast.success("Post successfully published!");

          handleModalClose();
        } else {
          // Handle API error response
          setError('Failed to post the feed. Please try again.');
        }
      } catch (err) {
        // Handle network errors or other issues
        setError('An error occurred while posting. Please try again.');
      } finally {
        // Reset loading state
        setIsPosting(false);
      }
    };

  return (
    <>
        <ToastContainer />

  <Modal show={showModal} onHide={handleModalClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* Display loader during page fetch */}
        {loadingPages && (
          <div className="spinner-container">
            <Spinner animation="border" role="status">
              <span className="sr-only"></span>
            </Spinner>
            <p>Fetching pages...</p>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && (
          <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
            {errorMessage}
          </Alert>
        )}

        {/* Template Preview Section */}
        {selectedTemplate && !showPageSelection && !loadingPages && (
          <>
            <div className="preview-container">
              {/* Profile Info */}
              <div className="profile-info">
                <img
                  src="https://via.placeholder.com/50"
                  alt="Profile"
                  className="profile-image"
                />
                <div>
                  <h5 className="profile-name">
                    {selectedTemplate.campaign_name || 'User Name'}
                  </h5>
                  {/* <p className="timestamp">06 Feb 2024, 3:00 PM</p> */}
                </div>
              </div>

              {/* Description */}
              <p className="description">
                {selectedTemplate.bodyParams
                  ? selectedTemplate.bodyParams[0]
                  : 'We get it, sometimes things don’t go as planned. But don’t worry, we’re here to make things right!'}
              </p>

              {/* Image Preview */}
              {selectedTemplate.headerParams &&
              selectedTemplate.headerParams.length > 0 &&
              /\.(png|jpe?g)$/i.test(selectedTemplate.headerParams[0]) ? (
                <img
                  src={selectedTemplate.headerParams[0]}
                  alt={selectedTemplate.campaign_name}
                  className="preview-image"
                />
              ) : (
                <p className="no-image-text">Image not included in this campaign</p>
              )}
            </div>
          </>
        )}

        {/* Page Selection Section */}
        {showPageSelection && !loadingPages && (
          <div>
            <h5>Select a Page to Post</h5>
            <ListGroup>
  {pages.map((page) => (
    <ListGroup.Item
      key={page.id}
      active={selectedPage && selectedPage.id === page.id}
      onClick={() => handlePageSelect(page)}
      className="page-item d-flex justify-content-between align-items-center" // Added flex classes for alignment
    >
      <div className="d-flex align-items-center">
        <input
          type="radio"
          checked={selectedPage && selectedPage.id === page.id}
          onChange={() => handlePageSelect(page)} // Handle the selection
          className="feedpost-radio" // Add a custom class for styling
          name="pageSelection" // Group radio buttons
        />
        <div className="page-details ms-2"> {/* Added margin to separate from radio button */}
          <h6 className="page-name">{page.name}</h6>
          <p className="page-category">{page.category || 'Unknown Category'}</p>
        </div>
      </div>
    </ListGroup.Item>
  ))}
</ListGroup>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Cancel
        </Button>

        {!showPageSelection ? (
          <Button variant="primary" onClick={handleSelectPageClick} disabled={loadingPages}>
            Select Page
          </Button>
        ) : (
          <Button variant="primary" onClick={handlePostNow} disabled={isPostDisabled || loadingPages}>
            Post Now
          </Button>
        )}
      </Modal.Footer>
    </Modal>
    <div
      style={{
        backgroundColor: "#ddd",
        color: "#000",
      }}
    >
      {!isLoggedIn && (
        <div className="bg-light-green py-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="p-0 m-0">To continue with these actions, please connect your Facebook page.</p>
          <button
          className="blue-button ms-3"
            onClick={handleFacebookLogin}
            style={{
              cursor: "pointer",
            }}
          >
            Login
          </button>
        </div>
      ) }
    </div>
    <div className="p-4" style={{ padding: "0px 20px", borderRadius: "10px" }}>
    {isLoggedIn && (
        <div className="border border-15-only mx-4 p-3  mb-3 d-flex justify-content-between "
        >
            <div>
                <div>
                {userData ? (
        <div>
          {userData.name && <div className="text-3-2 py-1">{userData.name}</div>}
          {userData.id && <p className="p-0 m-0 py-1 ">ID: {userData.id}</p>}
          {userData.email && <p className="p-0 m-0  py-1">Email: {userData.email}</p>}
        </div>
      ) : (
        <p>Loading user data...</p>
      )}
        </div>
            </div>
            <div>
            <div className="btn-border-green  border-15-only p-2 outline-success">Connected</div>
            </div>
        </div>
      ) }
      <div
        className="px-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <p className="CreateCampaign" >Facebook Feed</p>
       
      </div>
      <div className=" p-4 pt-0">
        {loading ? (
          <p style={{ textAlign: "center", fontSize: "18px", fontWeight: "500" }}>Loading...</p>
        ) : (
          <>
  <div style={gridStyle}>
  {filteredTemplates.map((template) => (
    <div key={template.id} style={cardStyle} className="template-card">
      <div className="card-content">
        {template.headerParams && template.headerParams.length > 0 ? (
          /\.(png|jpe?g)$/i.test(template.headerParams[0]) ? (
            <img
              src={template.headerParams[0]}
              alt={template.campaign_name}
              style={{
                width: "100%",
                height: "180px",
                objectFit: "cover",
                borderRadius: "8px 8px 0 0",
              }}
            />
          ) : (
            <div style={fallbackTextStyle}>
            <img src={dummyimg} alt="" />

            </div>
          )
        ) : (
          <div style={fallbackTextStyle}>
            <img src={dummyimg} alt="" />
          </div>
        )}
        <div style={{ padding: "15px" }}>
          <h5 style={{ fontSize: "16px", margin: "0 0 10px", fontWeight: "bold" }}>
            {template.campaign_name}
          </h5>
          {/* <p style={{ fontSize: "14px", color: "#555" }}>
            {template.bodyParams[0]} 
          </p> */}
        </div>
      </div>
      <div className="feed-button-container">
            {isLoggedIn &&
             <>
                <button className="blue-button px-5 py-2" onClick={() => handlePreview(template)}>
                  Preview
                </button>
                <button className="blue-button mt-2 px-5 py-2" onClick={() => handleShare(template)}>
                  Share
                </button>
              </>
            }
             {!isLoggedIn &&
            <>
         <button
          className="blue-button ms-3"
            onClick={handleFacebookLogin}
            style={{
              cursor: "pointer",
            }}
          >
            Login
          </button>
            </>
            }
      </div>
    </div>
  ))}
</div>
          </>
        )}
      </div>
    </div>
    </>
  );
};
const buttonStyle = {
  padding: "10px 16px",
  fontSize: "14px",
  color: "#000",
  borderRadius: "8px",
  border: "1px solid #ccc",
  cursor: "pointer",
  backgroundColor: "#f8f9fa",
  transition: "background-color 0.3s",
};
const dropdownStyle = {
  position: "relative",
  display: "inline-block",
};
const dropdownButtonStyle = {
  borderRadius: "8px",
  backgroundColor: "#f8f9fa",
  display: "flex",
  alignItems: "center",
};
const dropdownMenuStyle = {
  position: "absolute",
  top: "100%",
  left: "0",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "8px",
  color: "#007bff",
  zIndex: 1,
  minWidth: "150px",
};
const dropdownItemStyle = {
  padding: "10px",
  cursor: "pointer",
  color: "#000",
  borderBottom: "1px solid #ccc",
  transition: "background-color 0.2s",
};
const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "20px",
};
const cardStyle = {
  backgroundColor: "#fff",
  borderRadius: "8px",
  border: "1px solid #ccc",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
};
const fallbackTextStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "180px", 
  border: "1px dashed #ccc",
  borderRadius: "8px",
  backgroundColor: "#f8f9fa",
  color: "#666",
  fontSize: "16px",
  fontStyle: "italic",
  textAlign: "center",
};

export default FaceBookFeed;
