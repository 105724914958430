import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CampaignTracker = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loadScript = (src, location) =>
      new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        script.onload = () => {
          console.log(`${src} loaded successfully`);
          resolve();
        };

        script.onerror = (error) => {
          console.error(`Error loading script: ${src}`, error);
          reject(error);
        };

        if (location === "head") {
          document.head.appendChild(script);
        } else {
          document.body.appendChild(script);
        }
      });

    const initializeTracker = async () => {
      try {
        // Load the urlTracker.js script
        await loadScript("https://goseo.in/tracker/urlTracker.js", "head");

        // Ensure `UrlTracker` is available
        if (window.UrlTracker) {
          const tracker = new window.UrlTracker(
            "https://goseo.in/tracker/save_tracking_data.php"
          );
          tracker.init();
          console.log("Tracker initialized successfully");

          // Add scroll tracking
          let lastLoggedSection = null;

          const onScroll = () => {
            const scrollPosition =
              window.scrollY || document.documentElement.scrollTop;
            const sections = document.querySelectorAll("[id]"); // Select elements with an `id`
           
            sections.forEach((section) => {
              const sectionTop = section.offsetTop;
              const sectionHeight = section.offsetHeight;
              console.log(section.id);
              if (
                scrollPosition >= sectionTop &&
                scrollPosition < sectionTop + sectionHeight
              ) {
                const sectionId = section.id; // Use the existing `id` from the section
              
                if (lastLoggedSection !== sectionId) {
                  const newURL = `#${sectionId}`;
                  navigate(newURL, { replace: true });
                  console.log(`URL changed to: ${newURL}`);

                  // Log the section to the tracker
                  tracker.track({ url: newURL });

                  lastLoggedSection = sectionId;
                }
              }
            });
          };

          window.addEventListener("scroll", onScroll);

          // Cleanup
          return () => {
            window.removeEventListener("scroll", onScroll);
          };
        } else {
          console.error(
            "UrlTracker is not properly defined on the window object"
          );
        }
      } catch (error) {
        console.error("Error during script loading or initialization:", error);
      }
    };

    initializeTracker();
  }, [navigate]);

  return null; // No rendering needed
};

export default CampaignTracker;
