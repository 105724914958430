import React, { useEffect, useState } from "react";
import axios from "axios";

const CreateSession = () => {
  const [campaignId, setCampaignId] = useState("");
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    // Detect device type
    const detectDevice = () => {
      const width = window.innerWidth;

      if (width < 768) {
        setDeviceType("Mobile");
      } else if (width >= 768 && width <= 1024) {
        setDeviceType("Tablet");
      } else {
        setDeviceType("Desktop");
      }
    };

    // Run on load and set event listener
    detectDevice();
    const resizeListener = debounce(detectDevice, 300); // Debounce resize events
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    // Extract campaign ID from URL
    const extractCampaignId = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("campaignid") || "0"; // Default to "0" if not found
      setCampaignId(id);
    };

    extractCampaignId();

    // Create session
    const createSession = async () => {
      try {
        const payload = {
          campaign_id: campaignId || "0",
          product_name: "Anlook",
          device: deviceType,
        };

        const response = await axios.post(
          "https://goseo.in/sessions/createSession.php",
          payload
        );

        console.log("Session created successfully:", response.data);
      } catch (error) {
        console.error("Error creating session:", error);
      }
    };

    if (campaignId && deviceType) {
      createSession();
    }
  }, [campaignId, deviceType]);

  // Debounce utility
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  return null; // No UI component
};

export default CreateSession;


// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const CreateSession = () => {
//   const [campaignId, setCampaignId] = useState("");
//   const [deviceType, setDeviceType] = useState("");
//   useEffect(() => {
//     const detectDevice = () => {
//       const width = window.innerWidth;

//       if (width < 768) {
//         setDeviceType("Mobile");
//       } else if (width >= 768 && width <= 1024) {
//         setDeviceType("Tablet");
//       } else {
//         setDeviceType("Desktop");
//       }
//     };
//     detectDevice();
//     window.addEventListener("resize", detectDevice);

//     return () => {
//       window.removeEventListener("resize", detectDevice);
//     };
//   }, []);
//   useEffect(() => {
//     const url = window.location.href;
//     const searchParams = new URLSearchParams(url.split("?")[1]);
//     const id = searchParams.get("campaignid");
//     setCampaignId(id);

//     const createSession = async () => {
//       try {
//         const dummyPayload = {
//           campaign_id: campaignId || 0,
//           product_name: "Anlook",
//           device: deviceType,
//         };

//         const response = await axios.post(
//           "https://goseo.in/sessions/createSession.php",
//           dummyPayload
//         );

//         console.log("Session created successfully:", response.data);
//       } catch (error) {
//         console.error("Error creating session:", error);
//       }
//     };

//     createSession();
//   }, [campaignId, deviceType]);

//   return null;
// };

// export default CreateSession;
