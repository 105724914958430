import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import NoIndex from "../../NoIndex";

import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl, Modal } from "react-bootstrap";
import Pagination from "../../Whatsapp/Pagination";
import eye from "../../../images/EYE.png";
import trash from "../../../images/Delete.svg";
import "../AdminDashboard.css"
import Edit from "../../../images/message-edit.png";
import { useNavigate,useParams } from "react-router-dom";

const AdminTemplate = ({ userID }) => {
    const { clientId } = useParams();
  const [templates, setTemplates] = useState([]);
  const [deleteState, setdeleteState] = useState("");
  const [ErrorDelete, setErrorDelete] = useState();

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    userid: clientId ,
  });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ModalData, setModalData] = useState("");
  const itemsPerPage = 10; // Show 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [ExcuteDelete, setExcuteDelete] = useState("");
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";

  const handleClick = (type) => {
    if (type === "prev") {
      setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
    } else if (type === "next") {
      setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1);
    }
  };
  const handlePageClick = (pageNumber) => {
    if (
      pageNumber !== currentPage &&
      pageNumber >= 1 &&
      pageNumber <= totalPages
    ) {
      setCurrentPage(pageNumber);
      // Perform any action here, like fetching data for the new page
    }
  };

  const navigate = useNavigate();

  function handleView(clientI, templateId) {
    navigate(
        `/admin/TemplatesView/${clientId}/${templateId}`
      );
  }

  function handleEdit(index) {
    navigate(`/WhatsApp/Template/TemplateEdit?templateId=${index}`);
  }

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let url = `https://api.anlook.com/api/Whatsapp/getTemplates`;
  async function fetchJson(url, a) {
    try {
      let response = await fetch(url, a);
      if (!response.ok) {
        throw new Error("Failed to fetch data. Status:", response.status);
      }
      const jsonData = await response.json({
        userid: clientId ,
        secret: "string",
      });
      return jsonData;
    } catch (error) {
      console.error("An json function error occurred:", error);
      return null;
    }
  }

  const getVendorAccounts = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: clientId ,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.anlook.com/api/Whatsapp/getTemplates`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setTemplates(data?.response.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getVendorAccounts();
  }, []);

  const data = templates?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.language.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.category.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const data2 = templates.filter((item) =>
    item.language.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex =
    currentPage === totalPages ? templates?.length : startIndex + itemsPerPage;
  const currentItems = data?.slice(startIndex, endIndex);

  // edit delete and view hover diaogbox
  useEffect(() => {
    // Initialize tooltips when component mounts
    const tooltips = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltips?.map((tooltip) => new window.bootstrap.Tooltip(tooltip));

    // Clean up tooltips when component unmounts
    return () => {
      tooltips?.map((tooltip) => tooltip?.dispose());
    };
  }, []);

  function handleModal(item) {
    setModalData(item);
  }
  function handleDelete(item) {
    setdeleteState(item);
    SetExecute();
  }
  function SetExecute() {
    setExcuteDelete("delete");
  }

  const DeleteTemplate = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        userid: clientId ,
        secret: "string",
        templateId: deleteState,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.anlook.com/api/Whatsapp/deleteTemplate`,
        requestOptions
      );

      const data = await res.json();
      if (deleteState !== "") {
        toast.success(data.responseMessage);
        setErrorDelete(data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    DeleteTemplate();
  }, [ExcuteDelete]);

  return (
    <div className="marginRight">
      <NoIndex />
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>
      <div
        className="p-4 bg-white"
        style={{
          // backgroundColor: "#F8F8F8",
          fontFamily: "Nunito, sans-serif",
        }}
      >
        <div className="bg-white px-4">
          <div className="  " style={{ borderRadius: "10px" }}>
            <Form className=" d-flex justify-content-between pb-4">
              <Form.Label className="CreateCampaign  ">
                Template Status
              </Form.Label>
              <Form inline style={{ width: "25rem" }}>
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="bg-light "
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form>
            </Form>
            {loading ? (
              <div className=" m-5 p-5 d-flex justify-content-center">
                <div className="loader "></div>
              </div>
            ) : (
              <React.Fragment
                className=""
                style={{
                  border: "1px solid #dddddd",
                  borderRadius: "10px",
                }}
              >
                <table
                  className="table table-hover"
                  style={{
                    border: "1px solid #dddddd",
                    borderRadius: "10px",
                  }}
                >
                  <thead style={{ borderRadius: "10px" }}>
                    <tr className="text-center greenColour  py-4">
                      <th className="pb-3">S/N</th>
                      <th
                        className="pb-3"
                        style={{
                          color: "#000",
                          // backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Template Name
                      </th>

                      <th
                        className="pb-3"
                        style={{
                          color: "#000",
                          // backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        {" "}
                        Language
                      </th>

                      <th
                        className="pb-3"
                        style={{
                          color: "#000",
                          // backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Category
                      </th>

                      <th
                        className="pb-3"
                        style={{
                          color: "#000",
                          // backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Status
                      </th>

                      {/* <th
                        className="pb-3"
                        style={{
                          color: "#000",
                          // backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Templates
                      </th> */}
                      <th
                        className="pb-3"
                        style={{
                          color: "#000",
                          // backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "0px 7px 7px 0px",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentItems?.map((item, index) => (
                      <tr
                        key={index}
                        style={
                          {
                            // borderBottom: "1px solid #DDD",
                            // padding: "5px 0px",
                          }
                        }
                      >
                        <td className="pt-4 text-center">
                          {index + startIndex + 1}
                        </td>

                        <td className="pt-4 text-center">{item.name}</td>

                        <td className="pt-4 text-center">{item.language}</td>
                        <td className="pt-4 text-center">{item.category}</td>

                        <td className="pt-4 text-center">
                          {item.status === "APPROVED" ? (
                            <span
                              className=" text-center w-100  px-3 py-1"
                              style={{
                                width: "91px",
                                height: "31px",
                                borderRadius: "50px",
                                color: " rgba(1, 195, 125, 1)",
                                background: " rgba(242, 252, 248, 1)",
                                fontWeight: "bold",
                              }}
                            >
                              Approved
                            </span>
                          ) : (
                            <span
                              className=" text-center w-100  px-3 py-1"
                              style={{
                                width: "91px",
                                height: "31px",

                                borderRadius: "50px",
                                color: "rgba(255, 72, 72, 1)",
                                background: " rgba(255, 224, 224, 1)",
                                fontWeight: "bold",
                              }}
                            >
                              Disapproved
                            </span>
                          )}
                        </td>
                        {/* <td className="pt-2 text-center">
                        <button className={`gap-10 ${item.status === "APPROVED" ? "use-template" : "not-use-template disabled"}`}>
                            {item.status === "APPROVED" ? (
                              <>Use Template</>
                            ) : (
                              <>Use Template</>
                            )}
                          </button>
                        </td> */}
                        <td className=" d-flex justify-content-center ">
                          <div
                            className="d-flex justify-content-center align-items-center gap-4"
                            style={{ height: "50px" }}
                          >
                            <span
                              className="cursor-pointer"
                              data-bs-placement="bottom"
                              title="View"
                              onClick={() => {
                                handleView(clientId, item.name);
                              }}
                            >
                              <img src={eye} alt="Logo" />
                            </span>
                            {/* <span
                              className="cursor-pointer"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => {
                                handleEdit(index, item.name);
                              }}
                            >
                              <img src={Edit} value={index} alt="Logo" />
                            </span> */}
                            <div
                        className="cursor-pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                        // data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete"
                        onClick={() => {
                          handleModal(item.id);
                        }}
                      >
                        <img src={trash} alt="Logo" />
                      </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
          <>
            <div
              class="modal fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1
                      class="modal-title fs-5 text-success"
                      id="exampleModalLabel"
                    >
                      {ModalData}
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    Are you sure you want to delete this item? This action
                    cannot be undone.
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      id="liveAlertBtn"
                      type="button"
                      class="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => handleDelete(ModalData)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>

          {/* {pagination} */}
          <div>
            {loading === false ? (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNextPage={() => handleClick("next")}
                onPrevPage={() => handleClick("prev")}
                onPageClick={(e) => handlePageClick(e)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminTemplate;